import React, { useState, useEffect } from 'react';
import {ConsentContainer, ConsentText, AcceptButton, RejectButton} from './styles';

const CookieConsent = () => {
    const [showConsent, setShowConsent] = useState(true);
    const [consentGiven, setConsentGiven] = useState(false);

    const handleAccept = () => {
        setConsentGiven(true);
    };

    const handleReject = () => {
        setShowConsent(false);
    };

    useEffect(() => {
        const storedConsent = localStorage.getItem('cookieConsent');
        if (storedConsent === 'true') {
        setConsentGiven(true);
        }
    }, []);

    useEffect(() => {
        if (consentGiven) {
        setShowConsent(false);
        localStorage.setItem('cookieConsent', 'true');
        }
    }, [consentGiven]);

    if (!showConsent) {
        return null;
    }
  
    return(
      <ConsentContainer>
        <ConsentText>
          Este site utiliza cookies para otimizar a sua experiência. Ao continuar a navegação, assumimos que concorda com a utilização de cookies de acordo com a nossa Política de Privacidade.
        </ConsentText>
        <div className="area-button">
            <AcceptButton onClick={handleAccept}>Aceitar</AcceptButton>
            <RejectButton onClick={handleReject}>Rejeitar</RejectButton>
        </div>
        
      </ConsentContainer>
    );
  }
  
  export default CookieConsent;