import styled from 'styled-components';
import {DownArrow} from '@styled-icons/boxicons-regular/DownArrow';
import {Menu} from '@styled-icons/entypo/Menu';
import {CloseOutline} from '@styled-icons/evaicons-outline/CloseOutline';


export const Container = styled.div`
    display: block;
    width: 100%;
    height: 64px;
    background-color: #A4B4D4;
    

    .menu-desktop.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 200;
        background-color: #A4B4D4;
    }

    @media screen and (max-width: 1023px){ 
        //display: flex;
    }
`;

export const MenuDesktop = styled.div`
    display: block;
    width: 100%;
    height: 64px;

    .area-menu-desk {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        

        .ul-menu-desk{
            display: flex; //
            width: 900px;
            height: 75%;
            list-style: none;
            
        }

        .li-menu-desk, .has-submenu{
            width: 100%;
            height: 100%;
            font-size: 16px;
            font-weight: bold;
            transition: background-color 0.3s ease;
            border-radius: 15px 15px 0px 0px;
            
            :hover{
                background-color: white;
            }

            a{  
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                text-decoration: none;
                color: #184299;

                :hover{
                    cursor: pointer;
                }
            }
        }
    }

    .has-submenu {
        position: relative;
    }

    .cont-sub-menu, .cont-sub-menu-cartorio{
        display: flex;
        position: absolute;
        max-width: 670px;
        max-height: 250px;
        top: 100%;
        left: 0;
        background: white;
        z-index: 250;
        border-radius: 0px 0px 15px 15px;
        padding-bottom: 15px;
    }

    .cont-sub-menu-ul-li{
        display: block;
        width: 200px;
        margin: 5px 10px 0px 0px;
    }

    .titulo-sub-menu{
        display: flex;
        justify-content: center;
        width: 150px;

        span{
            margin-bottom: 5px;
            font-size: 16px;
            color: #184299;
            font-style: normal;
            font-weight: bold;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }    

    .submenu-ul{
        list-style: none;
        margin-top: 10px;

        li{
            font-size: 14px;
            margin-top: 5px;

            :hover{

                text-decoration: underline;

                a{
                    color:  blue;
                }
            }
        }
    }

    .cont-sub-menu-cartorio{
        width: 150px;

        .cont-sub-menu-ul-li{
            margin: 5px 0px 0px 0px;
        }
    }
    

    @media screen and (max-width: 1023px){ 
        display: none;
    }
`;



export const MainMobile = styled.div`
    display: none;
    height: 64px;

    @media screen and (max-width: 1023px){ 
        grid-area: M;
        display: flex;    
    }
    
    .cont-menu-mobile{
        display: flex;
        width: 100%;
        height: 64px;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 200;
        background-color: #A4B4D4;
        padding: 0px 5px 0px 5px;
        
    }

    .emblema-bar-pesquisa{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .emblema-cartorio-mobile{
        display: flex;
        align-items: center;
        width: 100%;        
        
        img{
            width: 50px;
        }

        @media screen and (max-width: 400px){
            
        }
    }

    .nome-cartorio-mob{
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        text-align: justify;


        span{
            width: 100%;
            font-size: 80%;
            font-style: normal;
            font-weight: bold;
            color: #002366;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            @media screen and (max-width: 1023px){
                //font-size: 70%;
            }

            @media screen and (max-width: 360px){
                font-size: 60%;
            }

            @media screen and (max-width: 260px){
                display: none;
            }
        }
    }

    

    //SideDrawer
    .menu {
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 200;
        transform: translateY(-100%);
        transition: transform 0.3s ease-out;
        top: 0;
        left: 0;
        padding-top: 64px;
        overflow-y: scroll;
    }

    .menu.open{
        transform: translateY(0);    
    }

    //Barrinha do menu desk
    .menu::-webkit-scrollbar {
        width: 8px; /* Largura da thumb */
    }

    .menu::-webkit-scrollbar-thumb {
        background-color: #184299;
        border-radius: 4px;
    }

    .menu::-webkit-scrollbar-thumb:hover {
        background-color: #185ea9; /* Cor da thumb em hover */
    }

    .menu::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do track */
    }
    //---

    .cont-area-conteudo{
        display: block;
        height: auto;
        background-color: #A4B4D4;
    }

    .cont-area-menu{
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .area-menu-mob{
        display: flex;
        width: 100%;
        justify-content: center;
    }



    .has-submenu-mobile{
        display: block;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: bold;
        transition: background-color 0.3s ease;
        

        a{  
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
            padding: 5px 0px 5px 0px;
            color: black;
        }
    }
    

    .has-submenu-mobile {
        position: relative;
    }

    .cont-sub-menu-cartorio-mob{
        display: flex;
        justify-content: center;
        //position: absolute;
        width: 100%;
        height: auto;
        top: 100%;
        left: 0;
    }

    .cont-sub-menu-ul-li-mob{
        display: flex;
        width: 100%;
        
    }

    .submenu-ul-mob{
        padding: 5px 0px 5px 0px;
        list-style: none;
        margin-top: 0px;
        width: 100%;


        li{
            display: flex;
            justify-content: center;
            font-size: 14px;
            margin-top: 0px;

            a{
                max-width: 250px;
                height: auto;
                color: #002366;
            }

            :hover{

                text-decoration: none;

                a{
                    color:  blue;
                }
            }
        }
    }

    .ul-menu-mob{
        display: block;
        height: 100%;
        list-style: none;
        padding: 10px 0px 10px 0px;
        width: 100%;
    }
    
`;


export const IconOpenMenuMobile = styled(Menu)`
    width: 50px;
    color: white;

    :hover{
        cursor: pointer;
    }
`;

export const IconCloseMenuMobile = styled(CloseOutline)`
    width: 50px;
    color: white;

    :hover{
        cursor: pointer;
    }
`;

export const IconDownArrow = styled(DownArrow)`
    width: 11px;
    color: #184299;
    margin-left: 5px;

    :hover{
        cursor: pointer;
    }
`;