import React from "react";
import { ContCarouselBanner, Grid } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CarouselBanner from "../../components/Carousel/CarouselBanner";
import Dividerbar from "../../components/Dividerbar";
import Mapa from "../../components/Mapa";

const Lgpd = () => {
    return(
        <Grid>
            <Header/>
            <div className="main">
                
                <div className="conteudo">
                    
                    <div className="area-conteudo">

                        <div className="ac-area-titulo">
                            <h2>Lei Geral de Proteção de Dados - LGPD</h2>
                        </div>
                        <div className="lgpd-registro">
                            <div className="area-img-lgpd">
                                <img src="/imgCard/lgpd.jpg" alt="imagem lgpd"/>
                            </div>
                            <div className="area-titulo-lgpd">
                                <h3>LGPD</h3>
                            </div>
                            <div className="area-lgpd">
                                <div className="lgpd-conteudo">
                                    <p>A Lei Geral de Proteção de Dados Pessoais (LGPD) que entrou em vigor em agosto de 2020. Ela tem como objetivo proteger os dados pessoais de pessoas físicas, sejam elas clientes, funcionários, fornecedores ou qualquer outra pessoa que tenha seus dados armazenados em um cartório.</p>
                                    <p>Os cartórios são obrigados a se adaptar à LGPD, pois eles coletam e armazenam uma grande quantidade de dados pessoais.</p>
                                    <p>A LGPD estabelece uma série de regras para o tratamento de dados pessoais. Por exemplo, os cartórios só podem coletar dados pessoais para finalidades específicas e devem informar os titulares dos dados sobre a finalidade do tratamento e sobre seus direitos. Os cartórios também devem adotar medidas de segurança para proteger os dados pessoais contra acessos não autorizados.</p>
                                    <h3>Cartório de Itapevi</h3>
                                    <p>O Cartório de Itapevi, em conformidade com a Lei Geral de Proteção de Dados - LGPD (Lei 13.709/2020), esclarece que não realiza qualquer tipo de tratamento de dados que envolva comercialização, venda, difusão, extração, comunicação, modificação, distribuição ou qualquer outro procedimento que vá contra as finalidades públicas estabelecidas e autorizadas pela legislação aplicável aos cartórios. O compartilhamento de dados, quando necessário, é feito apenas com as Centrais de Serviços Eletrônicos Compartilhados ou órgãos públicos, em conformidade com as leis e normas obrigatórias.</p>
                                    <p>Além disso, o cartório utiliza técnicas avançadas de monitoramento e prevenção para proteger os dados contra acessos não autorizados, bem como situações acidentais, ilegais ou indevidas de destruição, perda, alteração, comunicação ou qualquer outra forma de tratamento inadequado ou ilícito. São adotados critérios de segurança da informação adequados ao volume e à qualidade dos dados armazenados, com o objetivo de garantir o cumprimento efetivo das regras de proteção de dados pessoais, seguindo princípios de boa-fé e boas práticas no tratamento dessas informações.</p>
                                    <p>Em cumprimento ao art. 23, I e § 4º da LGPD, é importante ressaltar que o cartório realiza o tratamento de dados pessoais com base na legislação prevista no art. 1º da Lei Federal nº 8.935/1994, exclusivamente para garantir a publicidade, autenticidade, segurança e eficácia dos atos jurídicos. Suas práticas e procedimentos seguem principalmente as disposições da Lei de Registros Públicos (Lei Federal nº 6.015/73) e as Normas do Serviço Extrajudicial estabelecidas pela Corregedoria Geral do Tribunal de Justiça de São Paulo (Prov. 58/1989 e atualizações). Isso inclui atividades como inscrição, transcrição, registro, averbação e anotação de atos, comunicações internas para atualização dos livros e registros, escrituração de livros conforme as normas administrativas, fornecimento de informações e certidões, além de comunicações e trocas de informações com órgãos públicos e centrais de serviços eletrônicos compartilhados, conforme estabelecido em leis e normativas aplicáveis.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>  
            </div>    
            <div className='cont-mapa'>
                <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar"/>
                <Mapa/>
            </div>
            <Footer/>
        </Grid>
    )
}
export default Lgpd;