import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 160px;

    
    .cont-carousel{
        display: flex;
        width: 100%;
        height: 100%;
    }

    .carousel{
        width: 100%;
        padding: 5px;
        height: 100%;
    }
`;