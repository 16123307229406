import styled from "styled-components";
import {MapLocation} from '@styled-icons/fa-solid/MapLocation';
import {Telephone} from '@styled-icons/bootstrap/Telephone';
import {EmailOutline} from '@styled-icons/evaicons-outline/EmailOutline';

export const Grid = styled.div`
    grid-area: Footer;
    width: 100%;
    display: grid;
    grid-template-rows: 7px auto 175px auto;
    background-color: #555D99;

    grid-template-areas:
        "DividerBar"
        "M"
        "Image"
        "Copy"
    ;

    @media screen and (max-width: 1023px){ 
        grid-template-rows: 7px auto 140px auto;
    }
    
`;

export const Main = styled.div`
    grid-area: M;
    display: flex;
    justify-content: center;
    width: 100%;


    //Até 1080 ele ocupa toda a tela
    @media screen and (max-width: 1023px){ 
        display: block;
        width: 100%;
        justify-content: none; 

    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 1000px;
    margin-top: 10px;
    justify-content: space-between;    
    padding: 0px 5px 0px 5px;

    .cont-cartorio-info, .cont-cartorio-links, .cont-cartorio-duvidas{
        display: block;
        width:100%;
        max-width: 300px;
        height: 100%;
        
    }

    .cont-cartorio-info{
        order: 1;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1023px){
            width: 100%;
            height: auto;
        }
    }

    .cont-cartorio-links{
        order: 2;
    }

    .cont-cartorio-duvidas{
        order: 3;
        

        @media screen and (max-width: 1023px){
            width: 100%;
            height: auto;
        }

    }


    .cartorio-info{
        width: 100%;
        text-align: justify;
        margin: 10px 0px 50px 0px;
        

        span{
            color: white;
        }

        @media screen and (max-width: 1023px){
            width: 100%;
            height: auto;
        }
    }

    .cartorio-info-tel{
        
        order: 1;
        display: flex;    
        align-items: center;
        margin: 0px 0px 10px 0px;

        span{
            color: white;
        }
    }

    .cartorio-info-end{
        order: 2;
        display: flex;    
        align-items: center;
        margin: 0px 0px 10px 0px;

        span{
            color: white;
        }
    }

    .cartorio-info-email{
        order: 4;
        display: flex;    
        align-items: center;
        margin: 0px 0px 10px 0px;

        span{
            color: white;
        }
    }

    .cartorio-links{
        margin: 10px 0px 50px 0px;


        ul{
            list-style: disc;
            color: #ec545c;
            padding-left: 20px;
        }

        li{
            :hover{
                color: #ec545c;
            }
        }

        a{
            color:white;
            text-decoration: none;

            :hover{
                color: #ec545c;
            }
        }
    }


    //Até 1080 ele ocupa toda a tela
    @media screen and (max-width: 1023px){ 
        flex-direction: column;
        width: 100%;
        

        .cont-cartorio-info, .cont-cartorio-links, .cont-cartorio-duvidas{
            max-width:100%;
            height: 100%;

        }

        .cont-cartorio-info{
            order: 3;
        }

        .cont-cartorio-links{
            order: 2;
        }

        .cont-cartorio-duvidas{
            order: 1;
        }

        .cartorio-info-end, .cartorio-info-tel, .cartorio-info-email{
            width: 100%;
            height: auto;
            justify-content: center;
        }
    }

    @media screen and (max-width: 330px){

        .cartorio-info-end, .cartorio-info-tel, .cartorio-info-email{
            flex-direction: column;
            width: 100%;
            height: auto;

            .span-email{
        
                width: 150px;
                height: auto;
                word-wrap: break-word;
            }
        }
    }

`;

export const ImageFooter = styled.div`
    grid-area: Image;

    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    
    img{
        max-width: 100%;
        position: absolute;
        bottom: 3px;
    }
`;

export const LocationIcon = styled(MapLocation)`    
    width: 25px;
    height: 25px;
    color: white;
    margin-right: 5px;
`;

export const TelephoneIcon = styled(Telephone)`
    width: 20px;
    height: 20px;
    color: white;
    margin-right: 6px;

`;
export const EmailOutlineIcon = styled(EmailOutline)`
    width: 20px;
    height: 20px;
    color: white;
    margin-right: 6px;
`;

export const CopyFooter = styled.div`
    grid-area: Copy;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    font-size: 13px;
    color: white;
    font-style: normal;
    font-weight: bold;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #ec545c;

    a{
        color: #BFECFF;
        text-decoration: none;

        :hover{
            color: white;
        }
    }

    @media screen and (max-width: 1023px){ 
        font-size: 13px;
    }

`

