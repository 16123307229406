import styled from "styled-components";

export const ContCarouselBanner = styled.div`
    display: flex;
    width: 100%;
    background: linear-gradient(#A4B4D4, #555D99); 
    justify-content: center;
    

    .cont-carousel{
        display: flex;
        width: 1000px;
        height: auto;
    }

    @media screen and (max-width: 1023px){ 
        background-color: none;
        height: 300px;

        .cont-carousel{
            width: 100%;
        }

    }
`;


export const Grid = styled.div`
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "Header"
        "Main"
        "Footer"
    ;


    @media screen and (max-width: 1023px){ 
        grid-template-rows: auto auto auto;
    }
    

    .main{
        grid-area: Main;
        display: flex;
        width: 100%;
        justify-content: center;
        height: auto;
        


        @media screen and (max-width: 1023px){ 
            justify-content: start;
        }
    }

    .conteudo{
        background-color: #BCCEF1; /* Cinza claro */
        width: 1000px;
        


        @media screen and (max-width: 1023px){ 
            width: 100%;
        }
    }

    .area-conteudo{
        display: block;
        width: 100%;
        height: auto;
        padding: 0px 5px 0px 5px;

        .ac-area-titulo, .ac-area-titulo-rc{
            display: flex;
            width: 100%;
            height: 50px;
            align-items: center;
            justify-content: center;
            
        }

        .ac-area-titulo{
            h2{
                color: #002366;
            }
        }
        

        .duvidas-registro-civil{
            display: block;
            

            .ac-area-titulo-rc{
                margin-top: 15px;
                h3{
                    color: #184299;
                    
                }
            }

            .area-duvidas-rc{
                margin-top: 5px;
                display: block;
                

                .pergunta-duvida{
                    display: flex;
                    align-items: center;
                    height: auto;
                    justify-content: center;
                    border: 1.5px solid #184299;
                    font-style: normal;
                    font-weight: bold;
                    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    

                    span{
                        margin-right: 3px;
                        color: #e9292f;
                        font-style: normal;
                        font-weight: bold;
                        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                .resposta-duvida{
                    display: flex;
                    height: auto;
                    color: black;
                    padding: 5px;
                    text-align: justify;
                }
            }
        }

       

        @media screen and (max-width: 1023px){ 
            justify-content: start;
            align-items: start;
            padding: 0px 5px 0px 5px;
            height: 100%;

            .pergunta-duvida{
                padding: 5px;
            }

        }
    }

    .cont-mapa{
        display: block;
        width: 100%;
        height: 455px;
    }
`;