const duvidasCartorio = [
    //Registro Civil
    {
        duvida: "O que é um registro civil?",
        resposta: "Um registro civil é um documento oficial que registra eventos vitais, como nascimento, casamento e óbito de uma pessoa.",
        categoria: "registro civil"
    },
    {
        duvida: "O que é o Registro Civil de Nascimento–RCN?",
        resposta: "É o ato de assento do nascimento de uma pessoa feito no livro próprio de registro civil de nascimento (nascidos vivos). Deve ser feito uma única vez na vida, quando do nascimento da pessoa. O registro de nascimento dá publicidade ao nascimento com vida de determinada pessoa, conferindo-lhe existência legal e autêntica, atribuindo-lhe aptidão para contrair obrigações e adquirir direitos.",
        categoria: "registro civil"
    },
    {
        duvida: "Quais são os documentos necessários para registrar um nascimento?",
        resposta: "Geralmente, são necessários documentos como a declaração de nascimento, documentos de identificação dos pais e certidões de casamento dos pais, se aplicável.",
        categoria: "registro civil"
    },
    {
        duvida: "Quanto tempo tenho para registrar o nascimento de uma criança?",
        resposta: "Sim, o nascimento deverá ser registrado dentro do prazo de 15 (quinze) dias, que será ampliado em até três meses para os lugares distantes mais de trinta quilômetros da sede do cartório (art. 50 da Lei 6.015/1973).",
        categoria: "registro civil"
    },
    {
        duvida: "E se o Registro Civil de Nascimento não for feito no prazo legal?",
        resposta: "As declarações de nascimento fora do prazo legal dependerão de requerimento assinado por duas testemunhas, exceto se o registrando tiver menos de 12 (doze) anos de idade, caso em que ficam dispensadas as assinaturas de testemunhas, observado o disposto no art. 54, item 9, da Lei 6015/73.",
        categoria: "registro civil"
    },
    {
        duvida: "Qual a localidade onde é feito o Registro Civil de Nascimento?",
        resposta: "Em regra, dentro do prazo legal, o registro civil de nascimento deve ser feito na localidade onde a pessoa nasceu ou na de residência dos genitores (pai, mãe) ou responsável legal (art. 50 da Lei 6.015/73).  Fora do prazo legal, o RCN é feito unicamente no cartório da circunscrição da residência do interessado (art. 46 da Lei 6.015/73). Todavia, o registro de nascimento ocorrido no Distrito Federal poderá ser registrado em qualquer cartório de registro civil, eis que o art. 246 do Provimento Geral da Corregedoria do Distrito Federal dispõe que “para fins de registro de nascimento, habilitação de casamento e óbito o Distrito Federal é circunscrição registral única.” Vale lembrar que não serão cobrados emolumentos pelo registro civil de nascimento e pelo assento de óbito, bem como pela primeira certidão respectiva, nos termos do art. 30 da Lei 6015/73, com redação dada pela Lei 9534/1997.",
        categoria: "registro civil"
    },
    {
        duvida: "Em qual espécie de cartório é feito o Registro Civil de Nascimento?",
        resposta: "O registro civil de nascimento é feito nos cartórios de registro civil, na sede própria do órgão, ou podem estar descentralizados em postos avançados de registro civil, em serviços de registro civil de nascimento itinerantes, em mutirões realizado para tal finalidade e, ainda, nas unidades interligadas em estabelecimentos de saúde que realizam partos.",
        categoria: "registro civil"
    },
    {
        duvida: "Que profissional está credenciado para efetuar o Registro Civil de Nascimento?",
        resposta: "Apenas os oficiais dos serviços de Registro Civil e seus prepostos que receberam delegação do poder público (art. 236 da Constituição Federal) podem fazer o assento de nascimento. A fiscalização de seus atos é de atribuição do Poder Judiciário.",
        categoria: "registro civil"
    },
    {
        duvida: "O que é uma certidão de óbito?",
        resposta: "Uma certidão de óbito é um documento oficial emitido pelo registro civil que confirma o falecimento de uma pessoa. Ele contém informações como a data, local e causa da morte.",
        categoria: "registro civil"
    },
    {
        duvida: "O que é averbação?",
        resposta: "É o ato de anotar um fato jurídico que modifica ou cancela o conteúdo de um registro e é feita na sua margem direita já apropriada para este fim. Por exemplo, caso uma pessoa for casada e decidir posteriormente pela separação, seus registros de nascimento e casamento receberão a averbação de alteração do seu estado civil, passando de casado para separado.",
        categoria: "registro civil"
    },

    //Registro de Imóveis
    {
        duvida: "O que é o registro de imóveis?",
        resposta: "O registro de imóveis é um sistema legal que documenta a propriedade, transferência e ônus (como hipotecas e penhoras) de imóveis.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "O que é matrícula de imóvel?",
        resposta: "Matrícula é uma ficha arquivada no Cartório de Registro de Imóvel, e que contém todas as informações relevantes a respeito do imóvel, tais como caracterização do imóvel, seus proprietários, eventuais ônus ou gravames incidentes sobre ele, etc. É o documento principal do imóvel, sendo comparado, na linguagem popular, ao “RG” do imóvel.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Por que devo registrar meu imóvel?",
        resposta: "O registro de imóveis confere segurança jurídica e prova legal da propriedade. Ele protege os direitos do proprietário e facilita transações imobiliárias.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Quais documentos são necessários para registrar um imóvel?",
        resposta: "Os documentos necessários podem variar, mas geralmente incluem a escritura pública de compra e venda, documentos de identificação dos proprietários, certidões negativas e comprovante de pagamento de impostos.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Quem é responsável por fazer o registro de um imóvel?",
        resposta: "O registro de imóveis é realizado em um Cartório de Registro de Imóveis, sendo responsabilidade do proprietário ou do seu representante legal realizar o procedimento.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Quais são os benefícios do registro de imóveis para o proprietário?",
        resposta: "O registro de imóveis garante a proteção legal dos direitos de propriedade, possibilita a venda, transferência e locação do imóvel, além de facilitar o acesso a financiamentos e empréstimos utilizando o imóvel como garantia.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Todo imóvel tem matrícula?",
        resposta: "Nem todo imóvel tem matrícula. O imóvel que tem matrícula está regularizado. O imóvel que não tem pode estar irregular, o que depende de uma análise própria da situação da área onde ele está situado. Na dúvida, procure um especialista.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Por que o Cartório de Imóveis não consegue descobrir o número da matrícula (registro) do meu imóvel?",
        resposta: "Dependendo da situação, com alguns dados (número da matrícula, endereço, lote e quadra do loteamento, CPF das pessoas em nome de quem está registrado o imóvel) é possível descobrir o número do registro do seu imóvel. No entanto, há outras situações em que nem com esses dados é possível descobri-lo, já que o imóvel pode estar situado em área irregular, ou em área registrada há muitos anos, em que os dados de qualificação do imóvel e das pessoas eram muito imprecisos. Neste caso, a ajuda de um especialista é recomendável.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Quem pode requerer uma certidão?",
        resposta: "Qualquer pessoa, incumbindo-lhe o pagamento das despesas respectivas.",
        categoria: "registro de imoveis"
    },
    {
        duvida: "Como faço para regularizar meu imóvel?",
        resposta: "Há diversos tipos de irregularidade, e para saber o que fazer para regularizar o imóvel, deve-se primeiro descobrir o tipo de irregularidade. Abaixo, listamos alguns exemplos de irregularidade: a) O imóvel tem matrícula, mas não registrei a escritura: neste caso, basta trazer a escritura para protocolo e análise; b) O imóvel tem matrícula, já registrei o título, mas não está averbada a construção: apresentar então a certidão de habite-se, a Certidão Negativa de Débitos Previdenciária relativa à construção e requerimento, para análise; c) Comprei o imóvel financiado, já quitei mas consta ainda a garantia registrada na matrícula: trazer o termo de quitação de financiamento emitido pelo banco, e requerimento, para análise do escrevente. d) Comprei meu imóvel por contrato particular e não sei o número da matrícula, e não sei se está registrado: neste caso, sugerimos buscar ajuda de um especialista para lhe auxiliar no processo de regularização;",
        categoria: "registro de imoveis"
    },
    {
        duvida: "O que é certidão negativa de propriedade?",
        resposta: "É aquela que afirma a inexistência de propriedade de bem imóvel acerca de determinada pessoa.",
        categoria: "registro de imoveis"
    },

    {
        duvida: "Comprei um imóvel financiado e já quitei todas as parcelas. Como faço pra obter a escritura do meu imóvel?",
        resposta: "Para imóveis adquiridos com financiamento bancário no âmbito do SFH, o próprio contrato dispensa a elaboração da escritura. Assim, basta averbar a quitação do financiamento na matrícula do imóvel para que haja a regularidade da propriedade em seu nome. Obs: o documento mais importante para comprovação da regularidade do domínio é, além do contrato de aquisição, a certidão da matrícula do imóvel, cuja solicitação é recomendável sempre após o registro do contrato ou a averbação da quitação, para compor o arquivo pessoal relacionado à documentação do seu imóvel.",
        categoria: "registro de imoveis"
    },

    
]
export default duvidasCartorio;