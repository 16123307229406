import styled from "styled-components";

export const GridBar = styled.div`
    grid-area: ${props => props.gridTemplateAreas};
    display: grid;
    grid-template-rows: 7px;

    grid-template-areas:
        "BAR"
    ;
`;


export const BarMAIN = styled.div`
    grid-area: BAR;
    width: 100%;
    background-color: #7D95C3;
    height: 7px;
`;

/**
 * barPosition = é a posição da div 
 */
export const Bar = styled.div`
    display: ${props => props.barDisplay};
    float: ${props => props.barPosition};
    width:20%;
    height: 7px;
    background-color:#002366;
    border-radius: ${props => (props.barPosition === 'right' ? '50px 0 0 0' : (props.barPosition === 'left' ? '0 50px 0 0' : '0 50px 0 0'))};
`
//Como Utilizar mais de uma condição
//float: ${props => (props.barPosition === 'barRight' ? 'right' : (props.barPosition === 'barLeft' ? 'left' : 'left'))};