import React from "react";
import {Grid, Main, ImageFooter, Container, LocationIcon, TelephoneIcon, CopyFooter, EmailOutlineIcon} from './styles';
import { Link } from "react-router-dom";
import Dividerbar from "../Dividerbar";
import ImgFooter from '../../assets/footer-art.svg';
import TitleDivider from "../TitleDivider";
import duvidasRC from "../../dados/duvidasRC";

const Footer = () => {
    return(
        <Grid>
            <Dividerbar barDisplay="flex" barPosition="right" gridTemplateAreas="DividerBar"/>
            <Main>
                <Container>
                    <div id="id-contato" className="cont-cartorio-info">
                        <TitleDivider title="Cartório" colorFont="#BFECFF" colorBar="#67BDE1"/>
                        <div className="cartorio-info">
                            <span>
                                Registro de Imóveis, Títulos e Documentos, Civil de Pessoas Jurídicas,
                                Civil de Pessoas Naturais e de Interdições e Tutelas de Itapevi.
                            </span>
                        </div>
                        <div className="cartorio-info-tel">
                            <TelephoneIcon/>
                            <span>(11) 4773-4803</span>
                        </div>
                        <div className="cartorio-info-end">
                            <LocationIcon/>
                            <span>Praça José Baptista Silveira, nº 23/27</span>
                        </div>
                        <div className="cartorio-info-email">
                            <EmailOutlineIcon/>
                            <span className="span-email">registro.imoveis@registroitapevi.com.br</span>
                        </div>
                    </div>

                    <div className="cont-cartorio-links">
                        <TitleDivider title="Links Rápidos" colorFont="#BFECFF" colorBar="#67BDE1"/>
                        <div className="cartorio-links">
                            <ul>
                                <li><Link 
                                    to="https://registradores.onr.org.br/Servicos/frmRequerimentos.aspx"
                                    target="_blank" rel="noopener noreferrer"
                                    >
                                        Requerimentos
                                    </Link>
                                </li>
                                <li><Link to="https://registradores.onr.org.br/servicos/actitulo/frmAcompanhamentoTitulo.aspx" target="_blank" rel="noopener noreferrer">Consulta de Protocolos</Link></li>
                                
                                <li><a href="/duvidas/lgpd">LGPD</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="cont-cartorio-duvidas">
                        <TitleDivider title="Dúvidas Frequentes" colorFont="#BFECFF" colorBar="#67BDE1"/> 
                        <div className="cartorio-links">
                            <ul>
                                {
                                    duvidasRC.slice(0,5).map((dados, index) => (
                                        <li key={index}><a href="/duvidas/registro-civil">{dados.duvida}</a></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </Container>
            </Main>
            <ImageFooter>
                <img src={ImgFooter} alt="imagem do footer" />
            </ImageFooter>
            <CopyFooter>
                <div className="cont-copy-footer">
                    <span>&copy; Todos os direitos Reservados.</span>
                </div>
            </CopyFooter>
        </Grid>
    )
}
export default Footer;

//<li><Link to="#">Fale Conosco</Link></li>

//<span> Desenvolvido por <Link to="/">STS</Link></span>