const acessoRapido = [
    {
        titulo: "Registro de Imóveis",
        srcImgCapa:"/imgCard/fundoCard_4.jpg",
        altImgCapa:"Capa do card",
        link:"/"
    },
    {
        titulo: "Registro Civil",
        srcImgCapa:"/imgCard/fundoCard_3.jpg",
        altImgCapa:"Capa do card",
        link:"/"
    },
    {
        titulo: "Títulos e Documentos",
        srcImgCapa:"/imgCard/fundoCard_8.jpg",
        altImgCapa:"Capa do card",
        link:"/"
    },
    {
        titulo: "Pessoa Jurídica",
        srcImgCapa:"/imgCard/fundoCard_7.jpg",
        altImgCapa:"Capa do card",
        link:"/"
    },
    {
        titulo: "Acompanhamento Registral",
        srcImgCapa:"/imgCard/fundoCard.jpg",
        altImgCapa:"Capa do card",
        link:"https://registradores.onr.org.br/servicos/actitulo/frmAcompanhamentoTitulo.aspx"
    },
    {
        titulo: "Dúvidas?",
        srcImgCapa:"/imgCard/fundoCard_5.jpg",
        altImgCapa:"Capa do card",
        link:"/duvidas/registro-civil"
    },
    
]
export default acessoRapido;