import styled from "styled-components";


export const Container = styled.div`
    display: block;
    width: 100%;
    height: 400px;
    background-color: #A4B4D4;
    
    .cont-carousel{
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #A4B4D4;
    }

    .carousel{
        width: 100%;
        height: 100%;
        background-color: #A4B4D4;
        
        .banner{
            width: 100%;
            height: 400px;

            img{
                width: 100%;
                height: 400px;
                object-fit: cover;
                //border-radius: 0px 0px 10px 10px;
                background: linear-gradient(#A4B4D4, #555D99); 
            } 
        }
    }

    @media screen and (max-width: 1023px){ 
        height: 300px;

        .carousel{
            .banner{
                height: 300px;
            
                img{
                    height: 300px;
                }
            }
        } 
    }
`;