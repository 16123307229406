import styled from "styled-components";
import {SearchAlt2} from '@styled-icons/boxicons-regular/SearchAlt2';
import {CloseOutline} from '@styled-icons/evaicons-outline/CloseOutline';

export const Container = styled.div`
    background-color: #A4B4D4;

    @media screen and (max-width: 1023px){ 
        margin-right: 5px; 
    }

    display: flex;
    position: relative;
    align-items: center;
    max-width: 350px;
    width: 100%;
    height: auto;
    

    .conte-pesquisa{
        display: block;
        max-width: 350px;
        width: 100%;
    }

    .bar-pesquisa{
        display: flex;
        width: 100%;
        
    }

    .bar-pesquisa-input{
        display: flex;
        justify-content: right;
        max-width: 350px;
        width: 100%;
        
        
        input{
            width: 80%;
            height: 35px;
            padding: 0px 5px 0px 5px;
            border-radius: 5px 0px 0px 5px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            background-color: white;

            :focus{
                width: 100%;
                transition: width 0.3s ease-in-out;
            }

            @media screen and (max-width: 1023px){
                height: 30px;
            }
        }

    }

    .bar-pesquisa-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 35px;
        background-color: #555D99;
        border-radius: 0px 5px 5px 0px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        :hover{
            cursor: pointer;
        }

        @media screen and (max-width: 1023px){
            height: 30px;
        }
    }

    .result-pesquisa{
        display: block;
        position: absolute;
        background-color: #A4B4D4;
        margin-top: 2px;
        padding: 0px 5px 5px 5px;
        border-radius: 0px 0px 10px 10px;
        z-index: 99999;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
    }

    .result-pesquisa::-webkit-scrollbar {
        width: 8px; /* Largura da thumb */
    }

    .result-pesquisa::-webkit-scrollbar-thumb {
        background-color: #002366; 
        border-radius: 4px;
    }

    .result-pesquisa::-webkit-scrollbar-thumb:hover {
        background-color: #185ea9; /* Cor da thumb em hover */
    }

    .result-pesquisa::-webkit-scrollbar-track {
        background-color: #7D95C3; /* Cor do track */
    }

    .cont-card-pesquisa{
        display: block;
        width: 100%;
        height: auto;
        border: 2px solid white;
        margin-top: 7px;
        border-radius: 10px;
        

        .card-titulo-pesquisa{
            display: flex;
            padding: 2px;
            justify-content: center;
            text-align: justify;
            border-bottom: 2px solid white;
            border-radius: 10px 10px 0px 0px;
            color: black;
            font-weight: bold;
        }

        .card-corpo-pesquisa{
            display: flex;
            width: 100%;
            text-align: justify;
            padding: 2px;
            border-radius: 10px;
        }
    }

    .cont-msg{
        display: none;
        justify-content: center;
        width: 100%;
        border: 1px solid red;
        color: blue;
    }
`;



export const IconBarSearch = styled(SearchAlt2)`
    background-color: #555D99;
    width: 20px;
    color: white;
`;

export const IconCloseMenu = styled(CloseOutline)`
    background-color: #555D99;
    width: 30px;
    color: white;
`;