import styled from 'styled-components';

export const ConsentContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #184299;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    z-index: 999999;


    .area-button{
        display: flex;
        justify-content: center;
    }
`;

export const ConsentText = styled.p`
    margin-bottom: 10px;
    color: white;
`;

export const AcceptButton = styled.button`
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;

    &:hover {
        background-color: #0056b3;
    }
`;

export const RejectButton = styled.button`
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 5px;
    &:hover {
        background-color: #c82333;
    }
`;