import React from 'react';
import { 
    Grid,
    Main,
    Container,
    ContCarouselBanner
} from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CarouselBanner from '../../components/Carousel/CarouselBanner';
import Dividerbar from '../../components/Dividerbar';
import BarInfo from '../../components/BarInfo';
import TitleDivider from '../../components/TitleDivider';
import CarouselServicos from '../../components/Carousel/CarouselServicos';
import CarouselPrincipal from '../../components/Carousel/CarouselPrincipal';
import CardPrincipal from '../../components/Cards/CardPrincipal';
import dadosRegistroCivil from '../../dados/registroCivil';
import dadosRegistroImoveis from '../../dados/registroImoveis';
import acessoRapido from '../../dados/nossosServicos';
import CardRedondo from '../../components/Cards/CardRedondo';
import Mapa from '../../components/Mapa';

const Home = () => {
    return(
        <Grid>
            <Header/>
            <div className="dividerbar1">
                <Dividerbar barDisplay="flex" barPosition="left"/>
            </div>
            <ContCarouselBanner>
                <div className='cont-carousel'>
                    <CarouselBanner>
                        <div className="banner">
                            <img src="/banners/15.png" alt="banner 1"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/02.jpg" alt="banner 2"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/03.jpg" alt="banner 3"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/01.jpg" alt="banner 4"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/05.jpg" alt="banner 5"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/06.jpg" alt="banner 6"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/07.jpg" alt="banner 7"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/08.jpg" alt="banner 8"/>
                        </div>
                        <div className="banner">
                            <img src="/banners/09.jpg" alt="banner 9"/>
                        </div>
                    </CarouselBanner>
                </div>
            </ContCarouselBanner>
            <div className="dividerbar1">
                <Dividerbar barDisplay="flex" barPosition="right"/>
            </div>
            <div className="cont-barInfo-desk">
                <BarInfo />
            </div>
            <Main>
                
                <Container>
                    <div className='cont-carousel-servicos'>
                        <TitleDivider title="Nossos Serviços" colorFont="white" colorBar="#67BDE1"/>
                        <div className='carousel-servicos'>
                            <CarouselServicos>
                                {
                                    acessoRapido.map((dados, index) =>(
                                        <CardRedondo key={index} titulo={dados.titulo} srcImg={dados.srcImgCapa} altImg={dados.altImgCapa} link={dados.link}/>
                                    ))
                                }
                            </CarouselServicos>
                        </div>
                    </div>

                    <div id="id-registro-imoveis" className='cont-carousel-principal'>
                        <TitleDivider title="Registro de Imóveis" colorFont="white" colorBar="#67BDE1"/>
                        <div className='carousel-principal'>
                            <CarouselPrincipal>
                                {
                                    dadosRegistroImoveis.map((dados, index) =>(
                                        <CardPrincipal 
                                            key={index} 
                                            titulo={dados.titulo}
                                            detalhes={dados.detalhes} 
                                            tituloBotao={dados.tituloBotao} 
                                            srcImgCapa={dados.srcImgCapa} 
                                            altImgCapa={dados.altImgCapa}
                                            link={dados.link}
                                        />
                                    ))
                                }
                            </CarouselPrincipal>
                        </div>
                    </div>

                    <div id="id-registro-civil" className='cont-carousel-principal'>
                        <TitleDivider title="Registro de Civil" colorFont="white" colorBar="#67BDE1"/>
                        <div className='carousel-principal'>
                            <CarouselPrincipal>
                                {
                                    dadosRegistroCivil.map((dados, index) =>(
                                        <CardPrincipal 
                                            key={index} 
                                            titulo={dados.titulo} 
                                            detalhes={dados.detalhes} 
                                            tituloBotao={dados.tituloBotao} 
                                            srcImgCapa={dados.srcImgCapa} 
                                            altImgCapa={dados.altImgCapa}
                                            link={dados.link}
                                        />
                                    ))
                                }
                            </CarouselPrincipal>
                        </div>
                    </div>
                    
                </Container>
            </Main>
            <div className="cont-barInfo-mob">
                <Dividerbar barDisplay="flex" barPosition="right" gridTemplateAreas="DividerBar"/>
                <BarInfo />
            </div>
            <div id="id-localizacao" className="cont-mapa">
                <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar"/>
                <Mapa/>
            </div>
            <Footer/>
        </Grid>
    );
}
export default Home;
//<PlanoFundo/>