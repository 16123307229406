import React from "react";
import { Grid } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ContCarouselBanner } from "../Home/styles";
import CarouselBanner from "../../components/Carousel/CarouselBanner";
import Dividerbar from "../../components/Dividerbar";
import Mapa from "../../components/Mapa";

const Historico = () => {
    return(
        <Grid>
            <Header/>
            <div className="main">
                <div className="conteudo">
                    <ContCarouselBanner>
                        <div className='cont-carousel'>
                            <CarouselBanner>
                                <div className="banner">
                                    <img src="/banners/02.jpg" alt="banner 1"/>
                                </div>
                                <div className="banner">
                                    <img src="/banners/03.jpg" alt="banner 2"/>
                                </div>   
                                <div className="banner">
                                    <img src="/banners/05.jpg" alt="banner 2"/>
                                </div>
                                <div className="banner">
                                    <img src="/banners/06.jpg" alt="banner 2"/>
                                </div>        
                                <div className="banner">
                                    <img src="/banners/08.jpg" alt="banner 2"/>
                                </div>    
                                <div className="banner">
                                    <img src="/banners/09.jpg" alt="banner 2"/>
                                </div>                            
                            </CarouselBanner>
                        </div>
                    </ContCarouselBanner>
                    <div className="area-conteudo">
                        <div className="ac">
                            <div className="ac-area-titulo">
                                <h2>Histórico</h2>
                            </div>
                            <div className="ac-area-texto">
                                <section>
                                    <p>
                                        Itapevi vem do Tupi e significa "Rio de Pedras Chatas”, do tupi, itá-peb'y: Rio das itapevas, rio das lajes, das pedras chatas, de itá-peba (pedra chata, laje) e 'y (rio, águas). É também conhecida como "cidade dos telhados novos", "cidade das rosas", ou como pode-se ler na parede da estação de trem do municipío, "cidade esperança".
                                    </p>
                                    <p>
                                        A formação do vilarejo começou por volta do século XVIII, existindo no município uma casa bandeirista construída por volta de 1720. A família mais antiga, provavelmente, é dos Abreu.
                                    </p>
                                    <p>
                                        Em 10 de julho de 1875, foi inaugurada a Estação de Cotia da Estrada de Ferro Sorocabana (EFS), em torno da qual se formou o núcleo central de Itapevi. Com a estação ainda com o nome de Cotia e a própria sede do município chamada de Vila Cotia, criavam-se, porém, enormes confusões, notadamente no serviço de correios e telegramas para entrega de correspondências, com dificuldade para localização dos endereços em Itapevi.
                                    </p>
                                    <p>
                                        Em 1940, chegava em Itapevi o empresário Carlos de Castro que, sabendo que a familía Nunes tinha pretenções de vender as terras que pertenceram a Joaquim Nunes (que falecera em 1941), adquiriu vasta gleba de terra, dando origem ao loteamento do Parque Suburbano e Jardim Bela Vista. Foi a partir daí que se acelerou o processo de urbanização do local. E foi Carlos de Castro, juntamente com outros moradores do distrito, como Américo Christianini, Cezário de Abreu, Bonifácio de Abreu, Raul Leonardo, José dos Santos Novaes e tantos outros, que, deu-se início ao processo de emancipação do município. No plebiscito realizado em 1958, cerca de novecentas pessoas optaram pela emancipação, contra apenas trinta que não desejavam a autonomia. Naquele mesmo ano, foi formalizada pelo governador Jânio Quadros a lei que criava o município de Itapevi instalado oficialmente só no ano seguinte em 18 de Fevereiro de 1959 pelo governador Carvalho Pinto.
                                    </p>
                                    <p>
                                        O município se localiza nas coordenadas 23°32'56" S, 46°56'03" W, a 37 Km da capital de São Paulo. Está limitado pelo município de Santana de Parnaíba ao norte e noroeste, Barueri a nordeste, Jandira a leste, Cotia ao sul e sudeste, Vargem Grande Paulista a sudoeste e São Roque a oeste. De acordo com dados divulgados pelo IBGE,  até bem pouco tempo, Itapevi agregava uma população da ordem de 200.769 habitantes, contemplando uma área de 83 Km2. Hoje estima-se que a população supere a casa dos 250.000 habitantes. De acordo com a Prefeitura de Itapevi, o território do município foi todo ele declarado como urbano, apesar de se constatar nos registros a existência de verdadeiras “ilhas rurais”, que ainda se mantêm, graças à destinação dada por seus proprietários.
                                    </p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cont-mapa'>
                <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar"/>
                <Mapa/>
            </div>
            <Footer/>
        </Grid>
    )
}
export default Historico;