import styled from "styled-components";


export const ContCarouselBanner = styled.div`
    display: flex;
    width: 100%;
    background: linear-gradient(#A4B4D4, #555D99); 
    justify-content: center;
    

    .cont-carousel{
        display: flex;
        width: 1000px;
        height: auto;
    }

    @media screen and (max-width: 1023px){ 
        background-color: none;
        height: 300px;

        .cont-carousel{
            width: 100%;
        }

    }
`;

export const Grid = styled.div`
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "Header"
        "Main"
        "Footer"
    ;


    @media screen and (max-width: 1023px){ 
        grid-template-rows: auto auto auto;
    }


    .main{
        grid-area: Main;
        display: flex;
        width: 100%;
        justify-content: center;
        height: auto;
        


        @media screen and (max-width: 1023px){ 
            justify-content: start;
        }
    }

    .conteudo{
        background-color: #BCCEF1; /* Cinza claro */
        //background: linear-gradient(#BCCEF1, #BCCEF1,#555D99); 
        width: 1000px;



        @media screen and (max-width: 1023px){ 
            width: 100%;
        }
    }

    .area-conteudo{
        display: flex;
        width: 100%;
        align-items: center;
        height: 500px;
        

        .ac{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            

            .ac-area-titulo{
                display: flex;
                justify-content: center;
                width: 100%;
                
                margin: 5px 0px 5px 0px;

                h2{
                    color: #184299;
                }
            }

            .ac-area-texto{
                display: block;
                width: 100%;
                text-align: justify;
                padding: 5px;

                p{
                    color: black;
                }
            }
        }

        @media screen and (max-width: 1023px){ 
            justify-content: start;
            align-items: start;
            height: 100%;
        }
    }

    .cont-mapa{
        display: block;
        width: 100%;
        height: 455px;
    }
`;