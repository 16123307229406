import React from "react";
import { ContCarouselBanner, Grid } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CarouselBanner from "../../components/Carousel/CarouselBanner";
import Dividerbar from "../../components/Dividerbar";
import Mapa from "../../components/Mapa";
import duvidasCartorio from "../../dados/duvidasCartorio";

const DuvidasRegistroImoveis = () => {
    return(
        <Grid>
            <Header/>
            <div className="main">
                
                <div className="conteudo">
                    
                    <div className="area-conteudo">

                        <div className="ac-area-titulo">
                            <h2>Dúvidas mais Frequentes</h2>
                        </div>
                        <div className="duvidas-registro-civil">
                            <div className="ac-area-titulo-rc">
                                <h3>Registro Imóveis</h3>
                            </div>
                                {
                                    duvidasCartorio.filter(item => item.categoria === "registro de imoveis").map((dados, index) =>(
                                        <div className="area-duvidas-rc" key={index}>
                                            <div className="pergunta-duvida">
                                                <span className="span-number">{index + 1}.</span>{dados.duvida}
                                            </div>
                                            <div className="resposta-duvida">
                                                {dados.resposta}
                                            </div>
                                        </div>
                                    ))
                                }
                        </div>
                    </div>
                </div>  
            </div>    
            <div className='cont-mapa'>
                <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar"/>
                <Mapa/>
            </div>
            <Footer/>
        </Grid>
    )
}
export default DuvidasRegistroImoveis;
