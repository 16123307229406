import React from "react";
import {Grid, MainDesktop, ContainerDesktop} from './styles';
import Dividerbar from "../Dividerbar";
import EmblemaCartorio from "../../assets/apartamento.png";
import InputPesquisa from "../InputPesquisa";
import NavBar from "../NavBar";


const Header = () => {
    
    return(

        <Grid>
            <div className="dividerbar">
                <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar"/>
            </div>
            
            <MainDesktop>
                <ContainerDesktop>
                    <div className="cont-emblema-desk">
                        <div className="emblema-cartorio-desk">
                            <a href="/">
                                <img src={EmblemaCartorio} alt="Emblema do cartório" />
                            </a>
                        </div>
                        <div className="nome-cartorio-desk">
                            <span>Registro de Imóveis, Títulos e Documentos, Civil de Pessoas Jurídicas,
                            Civil de Pessoas Naturais e de Interdições e Tutelas de <span className="marca-texto">Itapevi.</span></span>
                        </div>
                    </div>
                    <InputPesquisa/>
                </ContainerDesktop>
            </MainDesktop>
            <NavBar/>
        </Grid>

    )
}
export default Header;


     