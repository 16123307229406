import React from "react";
import {Container} from './styles';

const TitleDivider = (props) => {
    return(
        <Container colorFont={props.colorFont} colorBar={props.colorBar}>
            <div className="cont">
                <div className="barrinha"></div>
                <span>{props.title}</span>
            </div>
        </Container>
    )
}
export default TitleDivider;