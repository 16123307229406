import React from "react";
import {GridBar, BarMAIN, Bar} from './styles';

/**
 * gridTemplateAreas = nome do grid do template
 * barPosition = left ou right -  
 * barDisplay = Flex ou None
 */
const Dividerbar = (props) =>{
    return(
        <GridBar gridTemplateAreas={props.gridTemplateAreas}>
            <BarMAIN>
                <Bar displayP={props.display} barPosition={props.barPosition} barDisplay={props.barDisplay}/>                
            </BarMAIN>
        </GridBar>
    )
}
export default Dividerbar;