import styled from 'styled-components';


export const Grid = styled.div`
    display: block;
    width: 100%;

    .dividerbar1{
        display: none;
    }

    .cont-mapa{
        display: block;
        width: 100%;
        height: 455px;
    }
    
    .cont-barInfo-desk{
        display: block;
    }

    .cont-barInfo-mob{
        display: none;
    }

    @media screen and (max-width: 1023px){ 
        .dividerbar1{
            display: block;
        }
        
        .cont-barInfo-desk{
            display: none;
        }
        .cont-barInfo-mob{
            display: block;
        }

    }
`;

export const Main = styled.div`
    grid-area: Main;
    display: flex;
    justify-content: center;
    width: 100%;  
    height: 1200px;
    


    @media screen and (max-width: 1023px){ 
        height: 1150px;

    }

    @media screen and (max-width: 720px){ 
        height: 1050px;
    }

    @media screen and (max-width: 410px){ 
        height: 970px;
    }
`;

export const ContCarouselBanner = styled.div`
    display: flex;
    width: 100%;
    background: linear-gradient(#A4B4D4, #555D99); 
    justify-content: center;
    

    .cont-carousel{
        display: flex;
        width: 1000px;
        height: auto;
    }

    @media screen and (max-width: 1023px){ 
        background-color: none;
        height: 300px;

        .cont-carousel{
            width: 100%;
        }

    }
`;

export const Container = styled.div`
    margin-top: 0px;
    position: absolute; //
    z-index: 1;
    width: 1000px;
    height: auto; //Mudar aqui
    padding: 0px 5px 0px 5px;

    .cont-carousel-servicos{
        display: block;
        margin-top: 5%;
        width: 100%;
    }

    .cont-carousel-principal{
        display: block;
        width: 100%;
        margin-top: 10%;
    }

    .carousel-servicos{
        display: flex;
        width: 100%;
        height: 160px;
        margin-top: 10px;
    }

    .carousel-principal{
        margin-top: 10px;
        height: auto;
    }



    //Até 1080 ele ocupa toda a tela
    @media screen and (max-width: 1023px){ 
        width: 100%;


    }

    @media screen and (max-width: 800px){
        .carousel-servicos{
            height: 130px;
        }
    } 
`;




