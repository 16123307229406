import React from "react";
import Slider from "react-slick";
import { Container } from "./styles";
import CardRedondo from "../../Cards/CardRedondo";
import CardPrincipal from "../../Cards/CardPrincipal";


const CarouselPrincipal = (props) =>{

    //setttings do Slick
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4.5,
        slidesToScroll: 1,
        initialSlide: 0,
        className: "carousel",

        responsive: [
            {
                breakpoint: 1090,
                settings: {
                    slidesToShow: 4.5,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 4.5,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 3.8,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 738,
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 675,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.7,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            }, 
            {
                breakpoint: 376,
                settings: {
                    slidesToShow: 1.4,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            }, 

            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            },
            {
                breakpoint: 300,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            },


        ]
    };

    return(
        <Container>
            <div className="cont-carousel">
                <Slider {...settings}>
                    {props.children}                
                </Slider>
            </div>
        </Container>
        
    );
}
export default CarouselPrincipal;