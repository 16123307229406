import React from "react";
import Slider from "react-slick";
import { Container, ButtonCard } from "./styles";


const CardPrincipal = (props) =>{

    return(
        <Container>
            <div className="card-title-img">
                <img src={props.srcImgCapa} alt={props.altImgCapa}/>
                <span>{props.titulo}</span>
            </div>
            <div className="card-conteudo-button">
                <div className="card-conteudo">
                    <span>{props.detalhes}</span>
                </div>
                <div className="card-button">
                    <ButtonCard href={props.link} target="_blank">
                        {props.tituloBotao}
                    </ButtonCard>
                </div>
            </div>
        </Container>
        
    );
}
export default CardPrincipal;

//<img src={props.srcImg} alt={props.altImg}/>