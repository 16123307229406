import {createGlobalStyle} from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default createGlobalStyle`
    * {
        margin: 0; 
        padding: 0;
        box-sizing: border-box;
    }

    body::-webkit-scrollbar {
        width: 8px; /* Largura da thumb */
    }

    body::-webkit-scrollbar-thumb {
        background-color: #002366; 
        border-radius: 4px;
    }

    body::-webkit-scrollbar-thumb:hover {
        background-color: #185ea9; /* Cor da thumb em hover */
    }

    body::-webkit-scrollbar-track {
        background-color: #7D95C3; /* Cor do track */
    }
    
    html, body, #root {
        height: 100%;   
        background: #555D99;
    }



    *{
        border: 0;
        outline: 0;
        font-family: 'Times New Roman', Arial, Helvetica, sans-serif;
    }

    :root {
        --backMain: #E9E9E9;
        --backSeg: #D3D3D3;
    }
  
`;