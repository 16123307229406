import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Home from './pages/Home/index';
import Cartorio from './pages/Cartorio';
import Historico from './pages/Historico';
import DuvidasRegistroCivil from './pages/DuvidasRegistroCivil';
import DuvidasRegistroImoveis from './pages/DuvidasRegistroImoveis';
import Lgpd from './pages/Lgpd';
import CookieConsent from './components/CookieConsent';
import Modal from './components/Modal';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-L5C4VW257M";

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function PageTracker() {
  usePageViews();
  return null; // Este componente não renderiza nada
}

function App() {
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);


  return (
    <>
      <BrowserRouter>
        <PageTracker />
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route path="/historico" element={<Historico/>}/>
          <Route path="/cartorio" element={<Cartorio/>}/>
          <Route path="/duvidas/registro-civil" element={<DuvidasRegistroCivil/>}/>
          <Route path="/duvidas/registro-imoveis" element={<DuvidasRegistroImoveis/>}/>
          <Route path="/duvidas/lgpd" element={<Lgpd/>}/>
        </Routes>
        <CookieConsent />
      </BrowserRouter>
      <GlobalStyles />
    </>
  );
}
export default App;

//<Modal/>