import React from "react";
import { Grid } from "./styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ContCarouselBanner } from "../Home/styles";
import CarouselBanner from "../../components/Carousel/CarouselBanner";
import Dividerbar from "../../components/Dividerbar";
import Mapa from "../../components/Mapa";

const Cartorio = () => {
    return(
        <Grid>
            <Header/>
            <div className="main">
                <div className="conteudo">
                    <ContCarouselBanner>
                        <div className='cont-carousel'>
                            <CarouselBanner>
                                <div className="banner">
                                    <img src="/banners/casamento.jpg" alt="banner 1"/>
                                </div>
                                <div className="banner">
                                    <img src="/banners/casamento_2.jpg" alt="banner 2"/>
                                </div>                                         
                            </CarouselBanner>
                        </div>
                    </ContCarouselBanner>
                    <div className="area-conteudo">
                        <div className="ac">
                            <div className="ac-area-titulo">
                                <h2>O Cartório</h2>
                            </div>
                            <div className="ac-area-texto">
                                <section>
                                    <p>
                                        O cartório de Itapevi foi instalado em 13 de novembro de 2009, inicialmente compreendendo os registros de imóveis, títulos e documentos e pessoas jurídicas. O cartório é resultado de desmembramento do registro de imóveis de Cotia, ostentando primeiramente 10 (dez) funcionários, além de ser totalmente informatizado. Com a recente agregação do registro civil das pessoas naturais, hoje, o cartório possui duas salas situadas no prédio da Praça José Baptista da Silveira, 27 - Vila Nova Itapevi, além de um salão para casamentos no auditório do edifício, contando, ao todo, 17 (dezessete) funcionários, mais o oficial titular e sua substituta.
                                    </p>
                                    <p>
                                        O registro de imóveis de Itapevi tem atualmente por objetivo primordial promover a regularização fundiária na região, em colaboração com a Prefeitura, a Câmara Municipal, o Ministério Público, o Poder Judiciário e o Estado de São Paulo, por meio de sua Secretaria de Habitação. Objetiva-se ainda assegurar ao cidadão itapeviense amplo acesso ao registro de seus títulos, muitos dos quais ainda não protocolados, em decorrência de vários fatores, entre os quais a própria falta de regularização de extensas áreas permeadas, não raras vezes, por lotes outrora matriculados no registro de Cotia. Na realidade, o cartório do registro de imóveis é, em última instância, um colaborador do cidadão itapeviense., um dos principais instrumentos de garantia ao direito de cidadania e da moradia. O exame dos títulos não visa apenas separar ou limitar os títulos chamados “bons” dos “ruins”, mas também e, sobretudo, encontrar soluções e alternativas para que em maior número tenham acesso ao registro.
                                    </p>
                                    <p>
                                        O registro civil, em especial, o das pessoas naturais é, por outro lado, um agente da promoção e do bem estar social coletivo, abrangendo os registros de nascimentos, casamentos e óbitos. Busca-se assegurar e consolidar os direitos da personalidade do cidadão itapeviense. Afinal, o registro civil identifica o cidadão em seu meio social e essa identificação servirá como um retrato perene durante toda a vida, contemplando as alterações do seu estado civil, de seu nome, e assim por diante. O registro civil absorve também o registro da adoção, emancipação, interdição e ausência, bem como  a união estável.
                                    </p>
                                    <p>
                                        O cartório também é composto do registro de títulos e documentos e de pessoas jurídicas estabelecidas em Itapevi, formando todo um complexo voltado para a garantia, eficácia e preservação de documentos (RTD) e também para o funcionamento regular das sociedades civis, associações, fundações e organizações religiosas, como é o caso do registro de seus atos constitutivos e de suas alterações.
                                    </p>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cont-mapa'>
                <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar"/>
                <Mapa/>
            </div>
            <Footer/>
        </Grid>
    )
}
export default Cartorio;