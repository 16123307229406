const duvidasRC = [
    {
        duvida: "O que é um registro civil?",
        resposta: "Um registro civil é um documento oficial que registra eventos vitais, como nascimento, casamento e óbito de uma pessoa."
    },
    {
        duvida: "O que é o Registro Civil de Nascimento–RCN?",
        resposta: "É o ato de assento do nascimento de uma pessoa feito no livro próprio de registro civil de nascimento (nascidos vivos). Deve ser feito uma única vez na vida, quando do nascimento da pessoa. O registro de nascimento dá publicidade ao nascimento com vida de determinada pessoa, conferindo-lhe existência legal e autêntica, atribuindo-lhe aptidão para contrair obrigações e adquirir direitos."
    },
    {
        duvida: "Quais são os documentos necessários para registrar um nascimento?",
        resposta: "Geralmente, são necessários documentos como a declaração de nascimento, documentos de identificação dos pais e certidões de casamento dos pais, se aplicável."
    },
    {
        duvida: "Quanto tempo tenho para registrar o nascimento de uma criança?",
        resposta: "Sim, o nascimento deverá ser registrado dentro do prazo de 15 (quinze) dias, que será ampliado em até três meses para os lugares distantes mais de trinta quilômetros da sede do cartório (art. 50 da Lei 6.015/1973)."
    },
    {
        duvida: "E se o Registro Civil de Nascimento não for feito no prazo legal?",
        resposta: "As declarações de nascimento fora do prazo legal dependerão de requerimento assinado por duas testemunhas, exceto se o registrando tiver menos de 12 (doze) anos de idade, caso em que ficam dispensadas as assinaturas de testemunhas, observado o disposto no art. 54, item 9, da Lei 6015/73."
    },
    {
        duvida: "Qual a localidade onde é feito o Registro Civil de Nascimento?",
        resposta: "Em regra, dentro do prazo legal, o registro civil de nascimento deve ser feito na localidade onde a pessoa nasceu ou na de residência dos genitores (pai, mãe) ou responsável legal (art. 50 da Lei 6.015/73).  Fora do prazo legal, o RCN é feito unicamente no cartório da circunscrição da residência do interessado (art. 46 da Lei 6.015/73). Todavia, o registro de nascimento ocorrido no Distrito Federal poderá ser registrado em qualquer cartório de registro civil, eis que o art. 246 do Provimento Geral da Corregedoria do Distrito Federal dispõe que “para fins de registro de nascimento, habilitação de casamento e óbito o Distrito Federal é circunscrição registral única.” Vale lembrar que não serão cobrados emolumentos pelo registro civil de nascimento e pelo assento de óbito, bem como pela primeira certidão respectiva, nos termos do art. 30 da Lei 6015/73, com redação dada pela Lei 9534/1997."
    },
    {
        duvida: "Em qual espécie de cartório é feito o Registro Civil de Nascimento?",
        resposta: "O registro civil de nascimento é feito nos cartórios de registro civil, na sede própria do órgão, ou podem estar descentralizados em postos avançados de registro civil, em serviços de registro civil de nascimento itinerantes, em mutirões realizado para tal finalidade e, ainda, nas unidades interligadas em estabelecimentos de saúde que realizam partos."
    },
    {
        duvida: "Que profissional está credenciado para efetuar o Registro Civil de Nascimento?",
        resposta: "Apenas os oficiais dos serviços de Registro Civil e seus prepostos que receberam delegação do poder público (art. 236 da Constituição Federal) podem fazer o assento de nascimento. A fiscalização de seus atos é de atribuição do Poder Judiciário."
    },
    {
        duvida: "O que é uma certidão de óbito?",
        resposta: "Uma certidão de óbito é um documento oficial emitido pelo registro civil que confirma o falecimento de uma pessoa. Ele contém informações como a data, local e causa da morte."
    },
    {
        duvida: "O que é averbação?",
        resposta: "É o ato de anotar um fato jurídico que modifica ou cancela o conteúdo de um registro e é feita na sua margem direita já apropriada para este fim. Por exemplo, caso uma pessoa for casada e decidir posteriormente pela separação, seus registros de nascimento e casamento receberão a averbação de alteração do seu estado civil, passando de casado para separado."
    },
]
export default duvidasRC;

