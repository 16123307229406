import React from "react";
import Slider from "react-slick";
import { Container } from "./styles";
import CardRedondo from "../../Cards/CardRedondo";


const CarouselServicos = (props) =>{

    //setttings do Slick
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 5.5,
        slidesToScroll: 1,
        initialSlide: 0,
        className: "carousel",
        responsive: [
            {
                breakpoint: 1090,
                settings: {
                    slidesToShow: 5.2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 5.2,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 5.1,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 5.5,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 738,
                settings: {
                    slidesToShow: 4.7,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 4.5,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3.3,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            }, 
            {
                breakpoint: 440,
                settings: {
                    slidesToShow: 3.0,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            }, 
            {
                breakpoint: 376,
                settings: {
                    slidesToShow: 2.6,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            }, 

            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            },
            {
                breakpoint: 300,
                settings: {
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    arrows: false,
                    dots: false,
                }
            },
        ]
    };

    return(
        <Container>
            <div className="cont-carousel">
                <Slider {...settings}>
                    {props.children}
                </Slider>
            </div>
        </Container>
        
    );
}
export default CarouselServicos;