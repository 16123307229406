import styled from "styled-components";

export const Container = styled.div`

    .cont{
        display: flex;
        align-items: center;
        height: 30px;
        width: 100%;
        

        span{
            margin-left: 5px;
            color: ${props => props.colorFont}; 
            font-style: normal;
            font-weight: bold;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }

    .barrinha{
        height: 100%;
        width: 10px;
        background-color: ${props => props.colorBar}; 
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
`;