import React, { useState, useEffect } from "react";
import {
    MenuDesktop,
    Container,
    IconDownArrow,
    MainMobile, 
    IconCloseMenuMobile, 
    IconOpenMenuMobile
} from './styles';
import Dividerbar from "../Dividerbar";
import { HashLink} from 'react-router-hash-link';
import EmblemaCartorio from "../../assets/apartamento.png";

const NavBar = () =>{

    const [isSubmenuOpenCartorio, setIsSubmenuOpenCartorio] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isSubmenuOpenServicosOnline, setIsSubmenuOpenServicosOnline] = useState(false);
    const [isSubmenuOpenDuvidas, setIsSubmenuOpenDuvidas] = useState(false);
    const [isSubmenuOpenMobileCartorio, setIsSubmenuOpenMobileCartorio] = useState(false);
    const [isSubmenuOpenMobileServicos, setIsSubmenuOpenMobileServicos] = useState(false);
    const [isSubmenuOpenMobileRc, setIsSubmenuOpenMobileRc] = useState(false);
    const [isSubmenuOpenMobileRi, setIsSubmenuOpenMobileRi] = useState(false);
    const [isSubmenuOpenMobileTd, setIsSubmenuOpenMobileTd] = useState(false);
    const [isSubmenuOpenMobilePj, setIsSubmenuOpenMobilePj] = useState(false);
    const [isSubmenuOpenMobileDuvidas, setIsSubmenuOpenMobileDuvidas] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    
    useEffect(() => {

        const handleScroll = () => {
            const menuDesktop = document.querySelector('.menu-desktop');
            const scrollPosition = window.scrollY;
            //Quando descer o scroll o navbar vai fixar na tela    
            if (scrollPosition > 134) {
                menuDesktop.classList.add('fixed');
            } else {
                menuDesktop.classList.remove('fixed');
            }
        };
    
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    //Abrir o sub-menu do mobile
    const clickOpenSubMenuCartorio = () => {        
        if (isSubmenuOpenMobileCartorio === true){
            setIsSubmenuOpenMobileCartorio(false);
        }else{
            setIsSubmenuOpenMobileCartorio(true);
        }  
    };
    
    const clickOpenSubMenuServicos = () => {   
        if (isSubmenuOpenMobileServicos === true){
            setIsSubmenuOpenMobileServicos(false);
        }else{
            setIsSubmenuOpenMobileServicos(true);
        }  
    };

    const clickOpenSubMenuRc = () => {   
        if (isSubmenuOpenMobileRc === true){
            setIsSubmenuOpenMobileRc(false);
        }else{
            setIsSubmenuOpenMobileRc(true);
        }  
    };

    const clickOpenSubMenuRi = () => {   
        if (isSubmenuOpenMobileRi === true){
            setIsSubmenuOpenMobileRi(false);
        }else{
            setIsSubmenuOpenMobileRi(true);
        }      
    };

    const clickOpenSubMenuTd = () => {   
        if (isSubmenuOpenMobileTd === true){
            setIsSubmenuOpenMobileTd(false);
        }else{
            setIsSubmenuOpenMobileTd(true);
        }      
    };

    const clickOpenSubMenuPJ = () => {   
        if (isSubmenuOpenMobilePj === true){
            setIsSubmenuOpenMobilePj(false);
        }else{
            setIsSubmenuOpenMobilePj(true);
        }      
    };

    const clickOpenSubMenuDuvidas = () => {   
        if (isSubmenuOpenMobileDuvidas === true){
            setIsSubmenuOpenMobileDuvidas(false);
        }else{
            setIsSubmenuOpenMobileDuvidas(true);
        }      
    };
    //-------------------------------

    //Desktop
    const handleMouseEnterCartorio = () => {
        setIsSubmenuOpenCartorio(true);
    };
    
    const handleMouseLeaveCartorio = () => {
        setIsSubmenuOpenCartorio(false);
    };

    const handleMouseEnter = () => {
        setIsSubmenuOpen(true);
    };
    
    const handleMouseLeave = () => {
        setIsSubmenuOpen(false);
    };

    const handleMouseEnterServicosOnline = () => {
      setIsSubmenuOpenServicosOnline(true);
    };
  
    const handleMouseLeaveServicosOnline = () => {
      setIsSubmenuOpenServicosOnline(false);
    };

    const handleMouseEnterDuvidas = () => {
        setIsSubmenuOpenDuvidas(true);
    };

    const handleMouseLeaveDuvidas = () => {
        setIsSubmenuOpenDuvidas(false);
    };
    //-------------

    
    //Quando clicar no Botão ele desce até o id da div escolhida
    const scrollWithOffset = (el, Offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = Offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    //Abrir e fechar o Menu Mobile
    const handleMenuToggle = () => {
        setOpenMenu(!openMenu);
    };

    let menuClasses = ["menu"];

    if (openMenu) {
        menuClasses.push("open");
    }

    return( 
        <Container>
            <MenuDesktop className="menu-desktop"> 
                <Dividerbar barDisplay="flex" barPosition="right" gridTemplateAreas="DividerBar11"/>
                <div className="area-menu-desk">
                    <ul className="ul-menu-desk">
                        <li
                            className="has-submenu"
                            onMouseEnter={handleMouseEnterCartorio}
                            onMouseLeave={handleMouseLeaveCartorio}
                        >   
                            <a title="O Cartório">O Cartório<IconDownArrow/></a>

                            {isSubmenuOpenCartorio && (
                                <div className="cont-sub-menu-cartorio">
                                    <nav className="cont-sub-menu-ul-li">                                    
                                        <ul className="submenu-ul">
                                            <li>
                                                <a href="/historico" title="Clique aqui para ver o histórico">Histórico</a>
                                            </li>
                                            <li>
                                                <a href="/cartorio" title="Clique aqui para ver o Cartório">Cartório</a>
                                            </li>
                                            <li>
                                                <a href="/duvidas/lgpd" title="Clique aqui para ver o as informações sobre LGPD">LGPD</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            )}
                        </li>
                        <li
                            className="has-submenu"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >   
                            <a title="Serviços">Serviços<IconDownArrow/></a>

                            {isSubmenuOpen && (
                                <div className="cont-sub-menu-cartorio">
                                    <nav className="cont-sub-menu-ul-li">                                    
                                        <ul className="submenu-ul">
                                            <li>
                                                <HashLink to="/#id-registro-civil" scroll={el => scrollWithOffset(el, -150)} title="Clique aqui para ver as informações sobre Registro Civil">
                                                    Registro Civil
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to="/#id-registro-imoveis" scroll={el => scrollWithOffset(el, -200)} title="Clique aqui para ver as informações sobre Registro de Imóveis">
                                                    Registro de Imóveis
                                                </HashLink>
                                            </li>
                                            <li>
                                                <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Clique aqui para ver as informações sobre Titulos e documentos">Titulos e documentos</a>
                                            </li>
                                            <li>
                                                <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Clique aqui para ver as informações sobre Pessoa Jurídica">Pessoa Jurídica</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            )}
                        </li>

                        <li
                            className="has-submenu"
                            onMouseEnter={handleMouseEnterServicosOnline}
                            onMouseLeave={handleMouseLeaveServicosOnline}
                        >   
                            <a title="Serviços Online">Serviços Online<IconDownArrow/></a>

                            {isSubmenuOpenServicosOnline && (
                                <div className="cont-sub-menu">
                                    
                                    <nav className="cont-sub-menu-ul-li">
                                        <div className="titulo-sub-menu">
                                            <span>Registro Civil</span>
                                        </div>
                                    
                                        <ul className="submenu-ul">
                                            <li>
                                                <a href="https://www.registrocivil.org.br/birth-certificate" target="_blank" rel="noopener noreferrer" title="2ª via de Nascimento">
                                                    2ª via de Nascimento
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.registrocivil.org.br/marriage-certificate" target="_blank" rel="noopener noreferrer" title="2ª via de Casamento">
                                                    2ª via de Casamento
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.registrocivil.org.br/death-certificate" target="_blank" rel="noopener noreferrer" title="2ª via de Óbito">
                                                    2ª via de Óbito
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.registrocivil.org.br/payed-search" target="_blank" rel="noopener noreferrer" title="Localizar Certidão">
                                                    Localizar Certidão
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://proclamas.org.br/" target="_blank" rel="noopener noreferrer" title="e-proclamas">
                                                    e-Proclamas
                                                </a>
                                            </li>            
                                        </ul>
                                    </nav>

                                    <nav className="cont-sub-menu-ul-li">
                                        <div className="titulo-sub-menu">
                                            <span>Registro Imóveis</span>
                                        </div>
                                    
                                        <ul className="submenu-ul">
                                            <li>
                                                <a href="https://registradores.onr.org.br/servicos/actitulo/frmAcompanhamentoTitulo.aspx" target="_blank" rel="noopener noreferrer" title="Acompanhamento">
                                                    Acompanhamento
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://registradores.onr.org.br/CertidaoDigital/frmPedidosCertidao.aspx?from=menu&digital=1" target="_blank" rel="noopener noreferrer" title="Certidão Digital">
                                                    Certidão Digital
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://registradores.onr.org.br/VisualizarMatricula/DefaultVM.aspx?from=menu" target="_blank" rel="noopener noreferrer" title="Visualização de Matrícula">
                                                    Visualização de Matrícula
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://registradores.onr.org.br/Servicos/frmRequerimentos.aspx" target="_blank" rel="noopener noreferrer" title="Requerimentos">
                                                    Requerimentos
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://registradores.onr.org.br/validacao.aspx" target="_blank" rel="noopener noreferrer" title="Validar Documentos">
                                                    Validar Documentos
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://registradores.onr.org.br/validacao.aspx" target="_blank" rel="noopener noreferrer" title="Usuacapião Extrajudicial">
                                                    Usuacapião Extrajudicial
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>

                                    <nav className="cont-sub-menu-ul-li">
                                        <div className="titulo-sub-menu">
                                            <span>Titulos e documentos</span>
                                        </div>
                                    
                                        <ul className="submenu-ul">
                                            <li>
                                                <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Titulos e documentos">Central RTDPJBrasil</a>
                                            </li>
                                        </ul>
                                    </nav>


                                    <nav className="cont-sub-menu-ul-li">
                                        <div className="titulo-sub-menu">
                                            <span>Pessoa Jurídica</span>
                                        </div>
                                    
                                        <ul className="submenu-ul">
                                            <li>
                                                <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Pessoa Jurídica">Central RTDPJBrasil</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            )}
                        </li>

                        <li
                            className="has-submenu"
                            onMouseEnter={handleMouseEnterDuvidas}
                            onMouseLeave={handleMouseLeaveDuvidas}
                        >   
                            <a title="Dúvidas?">Dúvidas?<IconDownArrow/></a>

                            {isSubmenuOpenDuvidas && (
                                <div className="cont-sub-menu-cartorio">
                                    <nav className="cont-sub-menu-ul-li">                                    
                                        <ul className="submenu-ul">
                                            <li>
                                                <a href="/duvidas/registro-civil" title="Dúvidas sobre Registro Civil">Registro Civil</a>
                                            </li>
                                            <li>
                                                <a href="/duvidas/registro-imoveis" title="Dúvidas sobre Registro de Imóveis">Registro de Imóveis</a>
                                            </li>
                                            <li>
                                                <a href="/duvidas/lgpd" title="Dúvidas sobre LGPD">LGPD</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            )}
                        </li>

                        <li className="li-menu-desk">
                            <HashLink smooth to="/#id-localizacao" scroll={el => scrollWithOffset(el, -60)} title="Localização">
                                Localização
                            </HashLink>
                        </li>
                        <li className="li-menu-desk">
                            <HashLink smooth to="/#id-contato" scroll={el => scrollWithOffset(el, -100)} title="Contato">
                                Contato
                            </HashLink>
                        </li>
                    </ul>
                </div>
                <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar11"/>
            </MenuDesktop>

            <MainMobile>
                <div className={menuClasses.join(' ')}>
                    <div className="cont-area-conteudo">
                        <Dividerbar barDisplay="flex" barPosition="left" gridTemplateAreas="DividerBar"/>
                        <div className="cont-area-menu">
                            <div className="area-menu-mob">
                                <ul className="ul-menu-mob">
                                    <li
                                        className="has-submenu-mobile"
                                    >   
                                        <a title="O Cartório">O Cartório<IconDownArrow onClick={clickOpenSubMenuCartorio}/></a>

                                        {isSubmenuOpenMobileCartorio && (
                                            <div className="cont-sub-menu-cartorio-mob">
                                                <nav className="cont-sub-menu-ul-li-mob">                                    
                                                    <ul className="submenu-ul-mob">
                                                        <li>
                                                            <a href="/historico" title="Histórico">Histórico</a>
                                                        </li>
                                                        <li>
                                                            <a href="/cartorio" title="Cartório">Cartório</a>
                                                        </li>
                                                        <li>
                                                            <a href="/duvidas/lgpd" title="LGPD">LGPD</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}
                                    </li>
                                    <li className="has-submenu-mobile">   
                                        <a>Serviços<IconDownArrow onClick={clickOpenSubMenuServicos}/></a>
                                        {isSubmenuOpenMobileServicos && (
                                            <div className="cont-sub-menu-cartorio-mob">
                                                <nav className="cont-sub-menu-ul-li-mob">                                    
                                                    <ul className="submenu-ul-mob">
                                                        <li>
                                                            <HashLink to="/#id-registro-civil" scroll={el => scrollWithOffset(el, -150)} title="Registro Civil">
                                                                Registro Civil
                                                            </HashLink>
                                                        </li>
                                                        <li>
                                                            <HashLink smooth to="/#id-registro-imoveis" scroll={el => scrollWithOffset(el, -200)} title="Registro de Imóveis">
                                                                Registro de Imóveis
                                                            </HashLink>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Titulos e documentos">Titulos e documentos</a>
                                                        </li>
                                                        
                                                        <li>
                                                            <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Pessoa Jurídica">Pessoa Jurídica</a>
                                                        </li>
                                                    </ul>
                                                    
                                                </nav>
                                            </div>
                                        )}
                                    </li>

                                    <li className="has-submenu-mobile">   
                                        <a title="Registro Civil">Registro Civil<IconDownArrow onClick={clickOpenSubMenuRc}/></a>
                                        {isSubmenuOpenMobileRc && (
                                            <div className="cont-sub-menu-cartorio-mob">
                                                <nav className="cont-sub-menu-ul-li-mob">                                    
                                                    <ul className="submenu-ul-mob">
                                                        <li>
                                                            <a href="https://www.registrocivil.org.br/birth-certificate" target="_blank" rel="noopener noreferrer" title="2ª via de Nascimento">
                                                                2ª via de Nascimento
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.registrocivil.org.br/marriage-certificate" target="_blank" rel="noopener noreferrer" title="2ª via de Casamento">
                                                                2ª via de Casamento
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.registrocivil.org.br/death-certificate" target="_blank" rel="noopener noreferrer" title="2ª via de Óbito">
                                                                2ª via de Óbito
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.registrocivil.org.br/payed-search" target="_blank" rel="noopener noreferrer" title="Localizar Certidão">
                                                                Localizar Certidão
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://proclamas.org.br/" target="_blank" rel="noopener noreferrer" title="e-proclamas">
                                                                e-Proclamas
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}
                                    </li>
                                    <li className="has-submenu-mobile">   
                                        <a title="Registro Imóveis">Registro Imóveis<IconDownArrow onClick={clickOpenSubMenuRi}/></a>
                                        {isSubmenuOpenMobileRi && (
                                            <div className="cont-sub-menu-cartorio-mob">
                                                <nav className="cont-sub-menu-ul-li-mob">                                    
                                                    <ul className="submenu-ul-mob">
                                                        <li>
                                                            <a href="https://registradores.onr.org.br/servicos/actitulo/frmAcompanhamentoTitulo.aspx" target="_blank" rel="noopener noreferrer" title="Acompanhamento">
                                                                Acompanhamento
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://registradores.onr.org.br/CertidaoDigital/frmPedidosCertidao.aspx?from=menu&digital=1" target="_blank" rel="noopener noreferrer" title="Certidão Digital">
                                                                Certidão Digital
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://registradores.onr.org.br/VisualizarMatricula/DefaultVM.aspx?from=menu" target="_blank" rel="noopener noreferrer" title="Visualização de Matrícula">
                                                                Visualização de Matrícula
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://registradores.onr.org.br/Servicos/frmRequerimentos.aspx" target="_blank" rel="noopener noreferrer" title="Requerimentos">
                                                                Requerimentos
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://registradores.onr.org.br/validacao.aspx" target="_blank" rel="noopener noreferrer" title="Validar Documentos">
                                                                Validar Documentos
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://registradores.onr.org.br/validacao.aspx" target="_blank" rel="noopener noreferrer" title="Usuacapião Extrajudicial">
                                                                Usuacapião Extrajudicial
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}
                                    </li>

                                    <li className="has-submenu-mobile">   
                                        <a title="Titulos e documentos">Titulos e documentos<IconDownArrow onClick={clickOpenSubMenuTd}/></a>

                                        {isSubmenuOpenMobileTd && (
                                            <div className="cont-sub-menu-cartorio-mob">
                                                <nav className="cont-sub-menu-ul-li-mob">                                    
                                                    <ul className="submenu-ul-mob">
                                                        <li>
                                                            <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Dúvidas sobre Titulos e documentos">
                                                                Central RTDPJBrasil
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}
                                    </li>
                                    <li className="has-submenu-mobile">   
                                        <a>Pessoa Jurídica<IconDownArrow onClick={clickOpenSubMenuPJ}/></a>

                                        {isSubmenuOpenMobilePj && (
                                            <div className="cont-sub-menu-cartorio-mob">
                                                <nav className="cont-sub-menu-ul-li-mob">                                    
                                                    <ul className="submenu-ul-mob">
                                                        <li>
                                                            <a href="https://www.rtdbrasil.org.br" target="_blank" rel="noopener noreferrer" title="Dúvidas sobre Pessoa Jurídica">Central RTDPJBrasil</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}
                                    </li>

                                    <li className="has-submenu-mobile">   
                                        <a>Dúvidas?<IconDownArrow onClick={clickOpenSubMenuDuvidas}/></a>

                                        {isSubmenuOpenMobileDuvidas && (
                                            <div className="cont-sub-menu-cartorio-mob">
                                                <nav className="cont-sub-menu-ul-li-mob">                                    
                                                    <ul className="submenu-ul-mob">

                                                        <li>
                                                            <a href="/duvidas/registro-civil" title="Dúvidas sobre Registro Civil">Registro Civil</a>
                                                        </li>
                                                        <li>
                                                            <a href="/duvidas/registro-imoveis" title="Dúvidas sobre Registro de Imóveis">Registro de Imóveis</a>
                                                        </li>
                                                        <li>
                                                            <a href="/duvidas/lgpd" title="Dúvidas sobre LGPD">LGPD</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Dividerbar barDisplay="flex" barPosition="right" gridTemplateAreas="DividerBar"/>
                    </div>
                </div>   
                <div className="cont-menu-mobile">

                    <div className="emblema-bar-pesquisa">
                        <div className="emblema-cartorio-mobile">
                            <a href="/" title="Emblema">
                                <img src={EmblemaCartorio} alt="Emblema do cartório" />
                            </a>
                            <div className="nome-cartorio-mob">
                                <span>Registro de Imóveis, Títulos e Documentos, Civil de Pessoas Jurídicas,
                                Civil de Pessoas Naturais e de Interdições e Tutelas de Itapevi.</span>
                            </div>
                        </div>     
                    </div>
                    <div className="icone-hamburguer">
                        {openMenu ? (
                                <IconCloseMenuMobile onClick={handleMenuToggle}/>
                            ) : (
                                <IconOpenMenuMobile onClick={handleMenuToggle} />
                            )
                        }
                    </div>
                </div>
            </MainMobile>
        </Container>
    );
}
export default NavBar;
