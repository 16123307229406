    import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 300px;
    
    .cont-carousel{
        display: flex;
        width: 100%;
        height: 300px;
    }

    .carousel{
        width: 100%;
        padding: 5px;

        height: 300px;
    }

    .slick-slider .slick-slide {
        display: flex;
        width: 100%;
        justify-content: center;        
    }  
`;