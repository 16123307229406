import styled, {keyframes} from "styled-components";


const rotacaoYanimacao = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;

const rotacaoYborda = keyframes`

  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }

`;



export const Container = styled.div`
    display: flex;
    max-width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid transparent;
    
    
        
    .card-title-img {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
        

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;            
        }

        

        a{
            display: flex;
            text-decoration: none;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            position: relative;
            color: whitesmoke;
            padding: 0px 10px 0px 10px;
            font-style: normal;
            font-weight: bold;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.30);
            background-color: rgba(85, 93, 153, 0.3);
            
            @media screen and (max-width: 800px){
                font-size: 12px;
                padding: 0px;
            }

            :hover{
                //background-color: rgba(85, 93, 153, 0.1);
                //transition: background-color 0.3s ease-in;
                //color: white;
            }
        }
        
        :hover{
            cursor: pointer;
            animation: ${rotacaoYanimacao} 1s ease-in-out;
        }
    }

    //Até 1080 ele ocupa toda a tela
    @media screen and (max-width: 800px){ 
        max-width: 100px;
        height: 100px;
    }

    :hover{
        border: 1px solid white;
        animation: ${rotacaoYborda} 1s ease-in-out;
    }
    
`;
