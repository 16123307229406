import React from "react";
import { FaceRetouchingNatural } from "styled-icons/material";

const Mapa = () => {
    return(
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228.61086074390332!2d-46.938458205626944!3d-23.540637820857842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf0681b91a70f5%3A0x51b93f66a45e77e6!2sCart%C3%B3rio%20de%20Registro%20de%20Im%C3%B3veis%2C%20T%C3%ADtulos%20e%20Documentos%2C%20Civil%20de%20Pessoa%20Natural%20e%20Jur%C3%ADdica%20de%20Itapevi!5e0!3m2!1spt-BR!2sbr!4v1688818876779!5m2!1spt-BR!2sbr"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
    );
}
export default Mapa;