import styled from "styled-components";


export const Grid = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1023px){ 
        justify-content: start;
    }
`;


export const Container = styled.div`
    display: block;
    width: 1000px;
    height: 125px;
    border-radius: 0px 0px 50px 50px;
    background-color: #555d87;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    
    .cont-title-bar{
        display: flex;
        justify-content: center;
        
    
        span{
            color: white;
            margin: 10px 0px 3px 0px;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-weight: bold;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }

    .cont-reg-civil-imoveis{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .cont-reg-civil{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        li{
            margin-bottom: 5px;
            color: white;
        }

        .marca-texto{
            margin-left: 3px;
            list-style-type: none;
            width: 99px;

            span{
                display: inline-block;
                width: 100%;
                text-align: center;
                background-color:  #184299;
                border-radius: 2px;
                color: white;
            }
        }

        span{
            color: white;
        }
    }

    .cont-reg-imoveis{
        display: flex;
        justify-content: right;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        li{
            margin-bottom: 5px;
            color: white;
        }

        .reg-imoveis{
            width: 100%;
            max-width: 260px;//

            .ul-ri{
                display: flex;
                list-style-type: disc;
                padding-left: 20px;
            }

            .li-hr{
                list-style-type: none;
            }
        }

        .marca-texto{
            margin-left: 3px;
            list-style-type: none;
            width: 99px;

            span{
                display: inline-block;
                width: 100%;
                text-align: center;
                background-color:  #184299;
                border-radius: 2px;
                color: white;
            }
        }

        span{
            color: white;
        }
    }

    .reg-civil{
        margin-left: 6px;

        .ul-rc{
            display: flex;
            list-style-type: disc;
            padding-left: 20px;
        }

        .li-hr{
            list-style-type: none;
        }
    }

    @media screen and (max-width: 1023px){ 
        width: 100%;
        height: auto;
        border-radius: 0px;
        background-color: #555D99;

        .cont-reg-civil-imoveis{
            display: block;
            width: 100%;
            height: auto;
            justify-content: start;
        }

        .cont-reg-civil{
            display: flex;
            justify-content: center;
            margin: 10px 0px 10px 0px;

            ul{ 
                display: block;
                list-style-type: none;
                padding-left: 0px;
                margin: 5px 0px 5px 0px;
            }

            li{
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 3px;
            }

            .span-title{
                display: flex;
                justify-content: center;
                width: 100%;
                
                span{
                    color: #BFECFF;
                }
            }
        }

        .li-hr{
            display: flex;
            width: 100%;
            justify-content: center;
        }

        .reg-civil{
            display: block;
            margin-left: 0px;

            .ul-rc{
                display: block;
                list-style-type: none;
                padding-left: 0px;

                li{
                    display: flex;
                    width: 100%;
                }
            }
        } 

        
        .cont-reg-imoveis{
            justify-content: center;
            
            .reg-imoveis{

                .span-title{
                    display: flex;
                    width: 100%;
                    justify-content: center;
                
                    span{
                        color: #BFECFF;
                        margin-bottom: 3px;
                    }
                }
            
                .ul-ri{
                    display: block;
                    width: 100%;
                    list-style-type: none;
                    padding-left: 0px;

                    li div{
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .marca-texto{
            display: flex;
            justify-content: center;
            margin-left: 0px;
            list-style-type: none;
            width: 100%;
        }
    }
`;

