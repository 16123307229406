const dadosRegistroCivil = [
    {
        titulo: "2ª via da certidão de Nascimento",
        detalhes: "A segunda via da certidão de nascimento é um documento emitido para substituir ou obter uma cópia adicional do registro original.",
        tituloBotao:"Eu Quero",
        srcImgCapa:"/imgCard/cert1.jpg", 
        altImgCapa:"Capa do card",
        link:"https://www.registrocivil.org.br/birth-certificate"
    },
    {
        titulo: "Localizar certidão",
        detalhes: "A localização de uma certidão é o processo de encontrar o registro oficial de um nascimento, casamento ou óbito.",
        tituloBotao:"Localizar",
        srcImgCapa:"/imgCard/localizarcert.jpg",
        altImgCapa:"Capa do card",
        link: "https://www.registrocivil.org.br/payed-search"
    },
    {
        titulo: "2ª via da certidão de Casamento",
        detalhes: "Esse é um documento emitido para substituir ou obter uma cópia adicional do registro original do casamento.",
        tituloBotao:"Eu Quero",
        srcImgCapa:"/imgCard/fundoCard_9.jpg",
        altImgCapa:"Capa do card",
        link:"https://www.registrocivil.org.br/marriage-certificate"
    },
    {
        titulo: "2ª via da certidão de Óbito",
        detalhes: "Esse é um documento emitido para substituir ou obter uma cópia adicional do registro original do falecimento de uma pessoa.",
        tituloBotao:"Eu Quero",
        srcImgCapa:"/imgCard/fundoCard.jpg",
        altImgCapa:"Capa do card",
        link: "https://www.registrocivil.org.br/death-certificate"
    },
    {
        titulo: "Localizar certidão",
        detalhes: "A localização de uma certidão é o processo de encontrar o registro oficial de um nascimento, casamento ou óbito.",
        tituloBotao:"Localizar",
        srcImgCapa:"/imgCard/localizarcert.jpg",
        altImgCapa:"Capa do card",
        link: "https://www.registrocivil.org.br/payed-search"
    },
    {
        titulo: "e-Proclamas",
        detalhes: "Localize Editais de Casamentos e alterações de nomes Agendados no Brasil.",
        tituloBotao:"Localizar",
        srcImgCapa:"/imgCard/fundoCard.jpg",
        altImgCapa:"Capa do card",
        link: "https://proclamas.org.br/"
    },
];
export default dadosRegistroCivil;