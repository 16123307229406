import React from "react";
import {Container, Grid} from './styles'

const BarInfo = () => {
    return(
        <Grid>
            <Container>
                <div className="cont-title-bar">
                    <span>Horário de Atendimento</span>
                </div>
                <div className="cont-reg-civil-imoveis">
                    <div className="cont-reg-civil">
                        <div className="reg-civil">
                            <div className="span-title">
                                <span>Registro Civil:</span>
                            </div>
                            <ul className="ul-rc">
                                <li><span>Segunda a Sexta-feira:</span></li>
                                    <li className="li-hr">
                                        <div className="marca-texto">
                                            <span>09h00 às 16h00</span>
                                        </div>
                                    </li>
                            </ul>
                            <ul className="ul-rc">
                                <li><span>Sábado:</span></li>
                                <li className="li-hr">
                                    <div className="marca-texto">
                                        <span>09h00 às 12h00</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="cont-reg-imoveis">
                        <div className="reg-imoveis">
                            <div className="span-title">
                                <span>Registro de Imóveis:</span>
                            </div>
                            <ul className="ul-ri">
                                <li>
                                    <div>
                                        <span>Segunda a Sexta-feira:</span>
                                    </div>
                                </li>
                                <li className="li-hr">
                                    <div className="marca-texto">
                                        <span>09h00 às 16h00</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </Grid>
    );
}
export default BarInfo;
