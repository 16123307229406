import React, { useState } from "react";
import {Container, IconBarSearch, IconCloseMenu} from './styles' 
import duvidasCartorio from "../../dados/duvidasCartorio";

const InputPesquisa = () => {

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSubmenuOpenPesquisa, setIsSubmenuOpenPesquisa] = useState(false);

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
    
        const filteredResults = duvidasCartorio.filter(item =>
          item.duvida.toLowerCase().includes(searchTerm.toLowerCase())
        );
    
        setSearchResults(filteredResults);
        setIsSubmenuOpenPesquisa(true);
    };

    const handleMouseLeave = () => {
        setIsSubmenuOpenPesquisa(false);
    };
    
    return(
        <Container>
            <div className="conte-pesquisa">
                <div className="bar-pesquisa">
                    <div className="bar-pesquisa-input">
                        <input
                            type="text"
                            placeholder="Pesquise aqui" 
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div> 
                    <div className="bar-pesquisa-icon">
                        { isSubmenuOpenPesquisa ? (
                            <IconCloseMenu onClick={handleMouseLeave}/>
                        ) : (
                            <IconBarSearch/>
                        )} 
                    </div>
                </div>
                { isSubmenuOpenPesquisa && (
                    <div className="result-pesquisa">
                        {searchResults.length > 0 ? (
                            searchResults.map((item, index) => (
                                <div className="cont-card-pesquisa" key={index}>
                                    <div className="card-titulo-pesquisa">
                                        {item.duvida}
                                    </div>
                                    <div className="card-corpo-pesquisa">
                                        {item.resposta}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="cont-msg">Nenhum resultado encontrado</div>
                        )}
                    </div>
                )}
            </div>
        </Container>
    )
}
export default InputPesquisa;