const dadosRegistroImoveis = [
    {
        titulo: "Acompanhamento Registral",
        detalhes: "Permite que o usuário faça o acompanhamento de forma gratuita do procedimento registral do título apresentado no Cartório.",
        tituloBotao:"Acompanhar",
        srcImgCapa:"/imgCard/fundoCard_4.jpg",
        altImgCapa:"Capa do card",
        link:"https://registradores.onr.org.br/servicos/actitulo/frmAcompanhamentoTitulo.aspx"
    },
    {
        titulo: "Certidão Digital",
        detalhes: "Documento com validade jurídica que contém informações detalhadas sobre o registro e histórico do imóvel.",
        tituloBotao:"Eu Quero",
        srcImgCapa:"/imgCard/fundoCard_5.jpg",
        altImgCapa:"Capa do card",
        link:"https://registradores.onr.org.br/CertidaoDigital/frmPedidosCertidao.aspx?from=menu&digital=1"
    },
    {
        titulo: "Visualização de Matrícula",
        detalhes: "Visualização da imagem da matrícula do imóvel, tal como a existente no cartório. Não vale como certidão.",
        tituloBotao:"Eu Quero",
        srcImgCapa:"/imgCard/fundoCard_6.jpg",
        altImgCapa:"Capa do card",
        link:"https://registradores.onr.org.br/VisualizarMatricula/DefaultVM.aspx?from=menu"
    },
    {
        titulo: "Requerimentos",
        detalhes: "Visualização da imagem da matrícula do imóvel, tal como a existente no cartório. Não vale como certidão.",
        tituloBotao:"Eu Quero",
        srcImgCapa:"/imgCard/fundoCard_8.jpg",
        altImgCapa:"Capa do card",
        link:"https://registradores.onr.org.br/Servicos/frmRequerimentos.aspx"
    },
    {
        titulo: "Validar Documentos",
        detalhes: "A certidão emitida pelo Registro de Imóveis poderá ser visualizada neste site pelo período de 120 dias.",
        tituloBotao:"Consultar",
        srcImgCapa:"/imgCard/fundoCard_5.jpg",
        altImgCapa:"Capa do card",
        link:"https://registradores.onr.org.br/validacao.aspx"
    },
    {
        titulo: "Usucapião Extrajudicial", 
        detalhes: "A consulta só é possível com número de protocolo e senha, fornecidos pelo Cartório.",
        tituloBotao:"Consultar",
        srcImgCapa:"/imgCard/fundoCard_7.jpg",
        altImgCapa:"Capa do card",
        link:"https://registradores.onr.org.br/validacao.aspx"
    },
]
export default dadosRegistroImoveis;