import React from "react";
import Slider from "react-slick";
import { Container } from "./styles";


const CardRedondo = (props) =>{

    return(
        <Container>
            <div className="card-title-img">
                <img src={props.srcImg} alt={props.altImg}/>
                <a href={props.link} target="_blank">{props.titulo}</a>
            </div>
        </Container>
        
    );
}
export default CardRedondo;