import styled from "styled-components";
import {CloseOutline} from '@styled-icons/evaicons-outline/CloseOutline';

export const Container = styled.div`
    display: ${props => props.isOpen};
    position: fixed;
    max-width: 400px;
    width: 100%;
    min-height: 300px;
    z-index: 999999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1e254b;
    border-radius: 0px 0px 10px 10px;
    
`;

export const AreaBotao = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: right;
    background-color: #0f173f;
    

    .icon-close{
        display: flex;
        width: 40px;
        height: 100%;
        border-radius: 50px 0px 0% 50px;
        background-color: white;
        transition: width 1s ease;

        :hover{
            width: 60px;
        }
    }
`;

export const AreaMsg = styled.div`
    display: block;
    width: 100%;
    height: auto;

    h3{
        margin-top: 5px;
        text-align: center;
        color: #e9292f;
    }

    .area-texto{
        padding: 3px 8px 5px 8px;
        color: whitesmoke;
        text-align: justify;
        height: auto;
    }


`;

export const IconClose = styled(CloseOutline)`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    color: #0f173f;
    
    :hover{
        cursor: pointer;
    }
`;