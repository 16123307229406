import styled, { keyframes } from 'styled-components';


const tituloAnimacao = keyframes`

  from {
    //
  }

  to {
    font-size: 18px;
  }

`;


export const Container = styled.div`
    display: block;
    width: 200px;
    height: auto;
    //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border: 1px solid white;
    background-color: #8E9EBE;
    

    .card-title-img{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 120px;
        overflow: hidden;
        border-radius: 10px 10px 0px 0px;
        border-bottom: 1px solid white;
        
        
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            
        }

        span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            position: relative;
            color: white;
            padding: 10px;
            font-style: normal;
            font-weight: bold;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            background-color: rgba(85, 93, 153, 0.4);


            :hover{
                animation: ${tituloAnimacao} 3s linear infinite;
            }

        }
        
    }

    .card-conteudo-button {
        display: block;
        width: 100%;
        
        border-radius: 0px 0px 10px 10px;
        
    }

    .card-conteudo {
        padding: 5px;
        width: 100%;
        text-align: justify;

        span{
            display: flex;
            flex-direction: column;
            height: 95px;
            color: white;
        }
    }

    .card-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-radius: 0px 0px 10px 10px;
    }

    :hover{
        cursor: pointer;

        span{
            background-color: rgba(85, 93, 153, 0.0);
            transition: background-color 0.3s ease-in;
        }
    }


    @media screen and (max-width: 1023px){ 
        
    }

`;


export const ButtonCard = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 35px;
    border-radius: 10px;
    background-color: whitesmoke;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #002366;
    font-size: 13px;


    :hover{

    }
`;