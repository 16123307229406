import React from "react";
import Slider from "react-slick";
import { Container } from "./styles";


const CarouselBanner = (props) =>{

    //setttings do Slick
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        className: "carousel",
        responsive: [
            {
                breakpoint: 1090,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
                arrows: false,
                }
            },
            {
                breakpoint: 650,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
                arrows: false,
                dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
                arrows: false,
                dots: false,
                }
            }, 
        ]
    };

    return(
        <Container>
            <div className="cont-carousel">
                <Slider {...settings}>
                    {props.children}
                </Slider>
            </div>
        </Container>
        
    );
}
export default CarouselBanner;