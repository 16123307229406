import styled from 'styled-components';

export const Grid = styled.div`
    grid-area: Header;
    background-color: #A4B4D4;
    display: grid;
    width: 100%;
    grid-template-rows: 7px 130px;
    grid-template-areas:
        "DividerBar"
        "M"
    ;

    @media screen and (max-width: 1023px){ 
        grid-template-rows: 64px;
        grid-template-areas:
            "M"
        ;
        

        .dividerbar{
            display: none;
        }
        
    }
`;


export const MainDesktop = styled.div`
    grid-area: M;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    
    @media screen and (max-width: 1023px){ 
        display: none;
    }
`
    

export const ContainerDesktop = styled.div`
    display: flex;
    width: 1000px;
    justify-content: space-between;
    
    

    .cont-emblema-desk{
        display: flex;
        width: 100%;
        max-width: 500px;
        align-items: center;
        
    }

    .emblema-cartorio-desk{

        margin-right: 10px;

        img{
            width: 100px; 
        }
    }

    .nome-cartorio-desk{
        display: flex;
        width: 100%;
        max-width: 450px;
        text-align: justify;

        span{
            
            font-style: normal;
            font-weight: bold;
            color: #002366;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }


    .marca-texto{
        display: inline-block;
        width: 60px;
        text-align: center;
        background-color: white;

    }
`;